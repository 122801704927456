/* eslint-disable no-nested-ternary */
import {
  FocusEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  RefObject,
} from 'react';
import { useIsMobile } from '@amzn/storm-ui-v3';
import { format } from 'date-fns';
import useDateSingle from './useDateSingle';
import useDatePicker from './useDatePicker';
import useDateRange from './useDateRange';
import useDayModifiers from './useDayModifiers';
import useDayFocus from './useDayFocus';
import isDateSingle from '../utils/isDateSingle';
import isDateRange from '../utils/isDateRange';
import {
  DateSingleContextValue,
  DateRangeContextValue,
  DayModifiers,
  ISODate,
  ISODateRange,
} from '../types';

function getAriaLabel(date: ISODate, modifiers: DayModifiers, locale: Locale) {
  let label = format(
    new Date(`${date}T00:00:00.0000`),
    'EEEE MMMM d yyyy',
    { locale },
  );
  if (modifiers.selected) {
    label += ', selected day';
  } else if (modifiers.range) {
    label += ', selected range';
  }
  return label;
}

type ButtonProps = {
  tabIndex: number;
  'aria-label': string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  /**
   * Should set this for non-mobile use-cases but mobile use-cases don't require mouse event listeners.
   */
  onMouseEnter?: MouseEventHandler<HTMLButtonElement>;
  /**
   * Should set this for non-mobile use-cases but mobile use-cases don't require mouse event listeners.
   */
  onMouseLeave?: MouseEventHandler<HTMLButtonElement>;
  onFocus: FocusEventHandler<HTMLButtonElement>,
  onBlur: FocusEventHandler<HTMLButtonElement>,
  onKeyDown: KeyboardEventHandler<HTMLButtonElement>,
}

type UseDay = {
  selected: ISODate | ISODate[] | ISODateRange | undefined;
  single: DateSingleContextValue;
  range: DateRangeContextValue;
  buttonProps: ButtonProps;
  modifiers: DayModifiers;
};

const useDay = (date: ISODate, buttonRef: RefObject<HTMLButtonElement>, overrides?: DayModifiers): UseDay => {
  const isMobile = useIsMobile();
  const context = useDatePicker();
  const single = useDateSingle();
  const range = useDateRange();

  const {
    focus,
    blur,
    focusOnKeyDown,
    isFocused,
  } = useDayFocus(date, buttonRef);

  const modifiers = useDayModifiers(date, overrides);

  const handleClick: MouseEventHandler<HTMLButtonElement> = event => {
    if (modifiers.disabled) {
      context.isOutsideRange?.(date, event);
      return;
    }

    if (isDateSingle(context.type)) {
      single.handleDateSelect(date, event);
    } else if (isDateRange(context.type)) {
      range.handleDateSelect(date, event);
    }
  };

  const handleMouseEnter: MouseEventHandler = event => {
    if (isDateRange(context.type)) {
      range.handleOnMouseEnter(date, event);
    }
  };

  const handleMouseLeave: MouseEventHandler = event => {
    if (isDateRange(context.type)) {
      range.handleOnMouseLeave(date, event);
    }
  };

  const handleFocus: FocusEventHandler = () => {
    focus(date);
  };

  const handleBlur: FocusEventHandler = () => {
    blur();
  };

  const handleKeyDown: KeyboardEventHandler = event => {
    focusOnKeyDown(event);
  };

  const tabIndex = isFocused ? -1 : 0;

  const ariaLabel = getAriaLabel(date, modifiers, context.locale);

  const mouseEventProps = !isMobile ? {
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
  } : {};

  return {
    selected: isDateSingle(context.type)
      ? single.selected
      : isDateRange(context.type)
        ? range.selected
        : undefined,
    single,
    range,
    buttonProps: {
      tabIndex,
      'aria-label': ariaLabel,
      onClick: handleClick,
      onFocus: handleFocus,
      onBlur: handleBlur,
      onKeyDown: handleKeyDown,
      ...mouseEventProps,
    },
    modifiers,
  };
};

export default useDay;
