import React, { ChangeEvent, FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import PT from 'prop-types';
import { noop } from '@amzn/storm-ui-utils-v3';
import { TaktIdConsumer, createStormTaktId } from '../TaktIdContext';
import type { TaktProps } from '../types/TaktProps';
import isMobile from '../theme/style-mixins/isMobile/isMobile';
import InnerSwitch from './InnerSwitch/InnerSwitch';

export interface LabelProps {
  disabled: boolean;
}

const LabelCenterer = styled('label')<LabelProps>`
  ${({ theme }) => theme.typography.base};
  color: ${({ disabled, theme }) => (disabled ? theme.form.label.colorDisabled : theme.form.label.color)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  ${isMobile(css`${({ theme }) => theme.typography.mobile.base};`)}
`;

const LabelArea = styled('div')`
  display: inline-flex;
  margin-inline-start: ${({ theme }) => theme.spacing.small};

  ${isMobile(css`margin-inline-start: ${({ theme }) => theme.mobile.spacing.small};`)}
`;

const SwitchContainer = styled('div')`
  display: inline-flex;
  align-items: center;
`;

export interface SwitchProps extends TaktProps {
  /**
   * Denotes if the Switch should be disabled.
   * @defaultValue `false`
   */
  disabled?: boolean,
  id: string,
  /**
   * @defaultValue `""`
   */
  className?: string,
  /**
   * Denotes if the Switch should have label.
   * @defaultValue `undefined`
   */
  label?: ReactNode,
  /**
   * Denotes if the Switch should be toggled.
   * @defaultValue `false`
   */
  toggled?: boolean | null,
  /**
   * Returns callback of (value, event).
   * @defaultValue `() => undefined`
   */
  onChange?: (toggled: boolean, event: ChangeEvent) => void,
  /**
   * Denotes if the Switch should be small.
   * @defaultValue `false`
   */
  small?: boolean,
  /**
   * Used to provide a customized aria-label to the Switch. For accessibility purposes only.
   * @defaultValue `undefined`
   */
  switchLabel?: string,
  /**
   * Denotes if the Switch label has data that should be rendered after the label.
   * @defaultValue `undefined`
   */
  renderLabelEnd?: ReactNode,
}

const Switch: FC<React.PropsWithChildren<SwitchProps>> = ({
  disabled = false,
  id,
  label,
  renderLabelEnd,
  taktId,
  taktValue,
  ...rest
}) => (
  <TaktIdConsumer taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('switch')}>
    {({ getDataTaktAttributes }) => (
      label ? (
        <SwitchContainer>
          <InnerSwitch {...getDataTaktAttributes()} {...rest} id={id} disabled={disabled} />
          <LabelArea>
            <LabelCenterer {...getDataTaktAttributes({ taktIdSuffix: 'label' })} htmlFor={id} disabled={disabled}>
              {label}
            </LabelCenterer>
            {renderLabelEnd && renderLabelEnd}
          </LabelArea>
        </SwitchContainer>
      ) : (
        <InnerSwitch {...getDataTaktAttributes()} {...rest} id={id} disabled={disabled} />
      )
    )}
  </TaktIdConsumer>
);

Switch.propTypes = {
  id: PT.string.isRequired,
  className: PT.string,
  /**
   * Denotes if the Switch should be toggled.
   */
  toggled: PT.bool,
  /**
   * Denotes if the Switch should be disabled.
   */
  disabled: PT.bool,
  /**
   * Returns callback of (value, event).
   */
  onChange: PT.func,
  /**
   * Denotes if the Switch should be small.
   */
  small: PT.bool,
  /**
   * Used to provide a customized aria-label to the Switch. For accessibility purposes only.
   */
  switchLabel: PT.string,
  /**
   * Denotes if the Switch should have label.
   */
  label: PT.node,
  /**
   * Denotes if the Switch label has data that should be rendered after the label.
   */
  renderLabelEnd: PT.node,
};

Switch.defaultProps = {
  className: '',
  toggled: false,
  disabled: false,
  onChange: noop,
  small: false,
  switchLabel: undefined,
  label: undefined,
  renderLabelEnd: undefined,
};

export default Switch;
