import React, { FC, ReactNode, MouseEventHandler } from 'react';
import PropTypes from 'prop-types';
import {
  TouchLinkAnchor,
  TouchLinkButton,
} from '../TouchLink.styles';
import InlineTouchLinkBody from './InlineTouchLinkBody';

export interface InlineTouchLinkProps {
  /**
   * The React nodes that are rendered by the InlineTouchlink.
   */
  children: ReactNode;
  /**
   * Use to add a link to InlineTouchLink.
   * @defaultValue `undefined`
   */
  href?: string;
  /**
   * Use to add a button to TouchLink.
   * @defaultValue `undefined`
   */
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const TouchLink: FC<React.PropsWithChildren<InlineTouchLinkProps>> = ({
  children,
  href,
  onClick,
}) => {
  if (onClick) {
    return (
      <TouchLinkButton onClick={onClick}>
        <InlineTouchLinkBody>
          {children}
        </InlineTouchLinkBody>
      </TouchLinkButton>
    );
  }
  return (
    <TouchLinkAnchor href={href}>
      <InlineTouchLinkBody>
        {children}
      </InlineTouchLinkBody>
    </TouchLinkAnchor>
  );
}

TouchLink.propTypes = {
  /**
   * The React nodes that are rendered by the InlineTouchlink.
   */
  children: PropTypes.node.isRequired,
  /**
   * Use to add a link to InlineTouchLink.
   */
  href: PropTypes.string,
  /**
   * Use to add a button to TouchLink.
   */
  onClick: PropTypes.func,
};

TouchLink.defaultProps = {
  href: undefined,
  onClick: undefined,
}

TouchLink.displayName = 'InlineTouchLink';

export default TouchLink;
