import React, { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import PT from 'prop-types';
import Text from '../Text';
import isMobile from '../theme/style-mixins/isMobile/isMobile';

export interface TagComponentProps {
  /**
   * @defaultValue `""`
   */
  className?: string;
  /**
   * @defaultValue `"New"`
   */
  label?: ReactNode;
  /**
   * @defaultValue `new`
   */
  type?: 'new' | 'beta' | 'info' | 'warning' | 'error' | 'success';
  /**
   * @defaultValue `"New"`
   */
  description?: string;
}

const TagComponent: FC<React.PropsWithChildren<TagComponentProps>> = ({
  className,
  label,
  type,
  description,
  ...rest
}) => (
  <span className={className} {...rest}>
    <Text type="sr-only">{description}</Text>
    <span
      aria-hidden="true"
    >
      {label}
    </span>
  </span>
);

TagComponent.propTypes = {
  className: PT.string,
  label: PT.node,
  type: PT.oneOf(['new', 'beta', 'info', 'warning', 'error', 'success']),
  description: PT.string,
};

TagComponent.defaultProps = {
  className: '',
  label: 'New',
  type: 'new',
  description: 'New',
};

const Tag = styled(TagComponent)`
  font-size: ${({ theme }) => theme.tag.fontSize};
  font-weight: ${({ theme }) => theme.tag.fontWeight};
  line-height: ${({ theme }) => theme.tag.lineHeight};
  color: ${({ theme }) => theme.tag.color};
  margin: ${({ theme }) => theme.tag.margin};
  display: inline-block;
  position: relative;
  bottom: ${({ theme }) => theme.tag.bottomOffset};

  background: ${({ theme, type }) => {
    switch (type) {
      case 'new':
        return theme.tag.backgroundNew;
      case 'info':
        return theme.tag.backgroundInfo;
      case 'warning':
        return theme.tag.backgroundWarning;
      case 'error':
        return theme.tag.backgroundError;
      case 'success':
        return theme.tag.backgroundSuccess;
      default: // Default to the beta tag color
        return theme.tag.backgroundBeta;
    }
  }};

  padding: ${({ theme }) => theme.tag.padding};
  border-radius: ${({ theme }) => theme.tag.radius};

  ${isMobile(css`
    font-size: ${({ theme }) => theme.tag.mobile.fontSize};
    line-height: ${({ theme }) => theme.tag.mobile.lineHeight};
    margin: ${({ theme }) => theme.tag.mobile.margin};
  `)}
`;

Tag.displayName = 'Tag';
Tag.propTypes = {
  /**
   * Use this prop to provide localized tag labels in internationalized UIs.Refer to [string translations for “beta”](https://translator.amazon.com/search/strings?tag=ucb_beta_feature&exact_match=on&text=&stage%5Bprod%5D=prod&locale_invert=false&locale=-1&marketplace_invert=false&marketplace=-1&team_invert=false&group=-1&stringset_invert=false&set=-1&query_id=&show_advanced=0), [string translations for “new”](https://translator.amazon.com/search/strings?tag=ucb_new_tag&exact_match=on&text=&stage%5Bprod%5D=prod&locale_invert=false&locale=-1&marketplace_invert=false&marketplace=-1&team_invert=false&group=-1&stringset_invert=false&set=-1&query_id=&show_advanced=0).
   */
  label: PT.node,
  /**
   * Defines the background color and default label
   */
  type: PT.oneOf(['new', 'beta', 'info', 'warning', 'error', 'success']),
  /**
   * Use this prop to provide localized tag description in internationalized UIs. This will be read
   * when a screen reader encounters a tag.
   */
  description: PT.string,
};
Tag.defaultProps = {
  label: 'New',
  type: 'new',
  description: 'New',
};

export default Tag;
