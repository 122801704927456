import React, {
  ReactNode,
  MouseEvent,
  FC,
  useCallback,
  useContext,
} from 'react';
import PT, { Validator } from 'prop-types';
import styled from 'styled-components';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import { MergeStyledComponentElementProps, noop } from '@amzn/storm-ui-utils';
import { SelectOptionDisplayName } from '../utils/displayNames';
import { TaktIdConsumer, createStormTaktId } from '../../TaktIdContext';
import { SelectValue, SelectListClickEventHandler } from '../types';
import SelectListContext from '../SelectListContext';
import { RenderWrapper, SelectButton, StandaloneSelectButton } from './SelectOption.styles';
import type { TaktProps } from '../../types/TaktProps';

export interface SelectOptionProps extends TaktProps, Omit<MergeStyledComponentElementProps<'button'>, 'value' | 'onClick' | 'ref'> {
  /**
   * @defaultValue `undefined`
   */
  value?: SelectValue;
  /**
   * @defaultValue `() => undefined`
   */
  onClick?: SelectListClickEventHandler;
  /**
   * @defaultValue `false`
   */
  selected?: boolean;
  /**
   * @defaultValue `false`
   */
  noWrap?: boolean;
  /**
   * @defaultValue `undefined`
   */
  renderStart?: () => ReactNode;
  /**
   * @defaultValue `undefined`
   */
  renderEnd?: () => ReactNode;
  /**
   * @defaultValue `false`
   */
  focused?: boolean;
  /**
   * @defaultValue `undefined`
   */
  renderAfter?: () => ReactNode;
}

interface SelectItemProps extends SelectOptionProps {
  /**
   * @defaultValue `""`
   */
  className?: string;
}

const SelectItem: FC<React.PropsWithChildren<SelectItemProps>> = ({
  className,
  /**
   * DEPRECATE IN STORM 4.X: This should only be read from SelectListContext.
   */
  onClick,
  children,
  value,
  /**
   * DEPRECATE IN STORM 4.X: This should only be read from SelectListContext.
   */
  selected,
  /**
   * DEPRECATE IN STORM 4.X: This should only be read from SelectListContext.
   */
  noWrap,
  renderStart,
  renderEnd,
  /**
   * DEPRECATE IN STORM 4.X: This should only be read from SelectListContext.
   */
  focused,
  renderAfter,
  disabled,
  taktId,
  taktValue,
  ...rest
}) => {
  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    if (typeof onClick === 'function') {
      onClick?.(value, event);
    }
  }, [onClick, value]);

  const { isFocusVisible, focusProps } = useFocusRing();

  if (value === undefined || value === null) return null;

  const selectItemButtonProps = {
    ...rest,
    selected,
    noWrap,
    disabled,
    type: 'button',
    role: 'option',
    'aria-selected': selected || focused,
    className,
    value,
    onClick: handleClick,
  }

  if (renderAfter && typeof renderAfter === 'function') {
    return (
      <TaktIdConsumer taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('select-item-button')}>
        {({ getDataTaktAttributes }) => (
          <RenderWrapper
            selected={selected}
            disabled={disabled}
            noWrap={noWrap}
            $focused={isFocusVisible}
          >
            <SelectButton {...mergeProps(getDataTaktAttributes(), selectItemButtonProps, focusProps)}>
              {renderStart && renderStart()}
              {children}
              {renderEnd && renderEnd()}
            </SelectButton>
            {renderAfter()}
          </RenderWrapper>
        )}
      </TaktIdConsumer>

    );
  }

  return (
    <TaktIdConsumer taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('select-item-button')}>
      {({ getDataTaktAttributes }) => (
        <StandaloneSelectButton
          {...mergeProps(getDataTaktAttributes(), selectItemButtonProps, focusProps)}
          $focused={isFocusVisible}
        >
          {renderStart && renderStart()}
          {children}
          {renderEnd && renderEnd()}
        </StandaloneSelectButton>
      )}
    </TaktIdConsumer>
  );
};

SelectItem.propTypes = {
  className: PT.string,
  onClick: PT.func,
  value: PT.any as Validator<SelectValue>,
  selected: PT.bool,
  children: PT.node,
  noWrap: PT.bool,
  renderStart: PT.func,
  renderEnd: PT.func,
  focused: PT.bool,
};

SelectItem.defaultProps = {
  className: '',
  onClick: noop,
  value: undefined,
  selected: false,
  children: null,
  noWrap: false,
  renderStart: undefined,
  renderEnd: undefined,
  focused: false,
  renderAfter: undefined,
};

const SelectOption = styled(SelectItem).attrs<SelectOptionProps>(({
  focused,
  noWrap,
  onClick,
  selected,
  theme,
  value,
  ...rest
}) => {
  const {
    focusedValue,
    noWrap: selectListNoWrap,
    onClick: selectListOnClick,
    selectedValue,
    afterOnSelect,
  } = useContext(SelectListContext);

  const handleClick: SelectListClickEventHandler = (...args) => {
    if (selectListOnClick) {
      selectListOnClick(...args);
    } else if (onClick) {
      onClick(...args);
    }
    if (afterOnSelect) {
      afterOnSelect();
    }
  }

  let isSelected = selected;
  let isFocused = focused;

  if (selectedValue && value) {
    isSelected = (value === selectedValue);
  }

  if (focusedValue && value) {
    isFocused = (value === focusedValue);
  }

  return ({
    focused: isFocused,
    noWrap: selectListNoWrap || noWrap,
    onClick: handleClick,
    selected: isSelected,
    value,
    ...rest,
  });
})``;
SelectOption.displayName = SelectOptionDisplayName;
SelectOption.propTypes = {
  onClick: PT.func,
  value: PT.any as Validator<SelectValue>,
  selected: PT.bool,
  children: PT.node,
  noWrap: PT.bool,
  renderStart: PT.func,
  renderEnd: PT.func,
  focused: PT.bool,
};

export default SelectOption;
