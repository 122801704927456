import React, {
  PureComponent,
  createElement,
  ReactNode,
  Ref,
  createRef,
  ChangeEventHandler,
} from 'react';
import PT from 'prop-types';
import styled, { css } from 'styled-components';
import {
  MergeElementProps,
  createPseudoUID,
  getFirstFocusableElement,
  isFunction,
  isString,
  noop,
} from '@amzn/storm-ui-utils';
import HelpTip from '../HelpTip/HelpTip';
import { InlineMessage } from '../Text';
import formGroupRowStyleMixin from '../FormGroup/formGroupRowStyleMixin';
import formGroupColumnStyleMixin from '../FormGroup/formGroupColumnStyleMixin';
import LabelLine from '../FormGroup/LabelLine';
import ValidationLine from '../FormGroup/ValidationLine';
import { inputStyles, inputFocusStyles } from '../InputFormGroup/InputFormGroup.styles';

import isMobile from '../theme/style-mixins/isMobile/isMobile';
import { RenderMessage, StatusType } from '../InputFormGroup/types';

import { TaktIdConsumer, createStormTaktId } from '../TaktIdContext';
import type { TaktProps } from '../types/TaktProps';

export const styleTextAreaMixin = css`
  ${inputStyles}
  padding: ${({ theme }) => theme.form.textarea.padding};
`;

const InputGroup = styled(({ className, children, inputGroupRef }) => (
  <div className={className} ref={inputGroupRef}>{children}</div>
))`
  background: ${({ theme, disabled }) => (disabled ? theme.form.input.disabled.bg : theme.form.input.bg)};
  border: ${({ theme }) => theme.form.input.border};
  border-color: ${({ theme, disabled }) => (disabled ? theme.form.input.disabled.borderColor : theme.form.input.borderColor)};
  box-shadow: ${({ theme }) => theme.form.input.boxShadow};
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.form.input.radius};
  color: ${({ theme, disabled }) => (disabled ? theme.form.input.disabled.color : theme.form.input.color)};
  min-width: ${({ fullWidth, theme }) => (fullWidth ? 'auto' : theme.form.input.minWidth)};
  display: flex;

  ${({ $statusType, theme }) => ($statusType === 'error' && css`
    border-color: ${theme.form.input.error.borderColor};
    box-shadow: ${theme.form.input.error.boxShadow};
  `)}

  ${({ $statusType, theme }) => ($statusType === 'warning' && css`
    border-color: ${theme.form.input.warning.borderColor};
  `)}

  ${({ $statusType, theme }) => ($statusType === 'success' && css`
    border-color: ${theme.form.input.success.borderColor};
  `)}

  :focus-within {
    ${inputFocusStyles}
  }

  > textarea {
    /* needed for AUI overriding */
    ${styleTextAreaMixin}
  }
`;
InputGroup.displayName = 'InputGroup';

const TextAreaValidationMessage = styled(InlineMessage)`
  margin-top: 0;
  margin-bottom: 0;
`;
TextAreaValidationMessage.displayName = 'TextAreaValidationMessage';

export interface TextAreaFormGroupProps extends TaktProps, Omit<MergeElementProps<'textarea'>, 'ref'|'id'> {
  /**
   * The page unique identifier of the TextArea input.
   */
  id: string;
  /**
   * The class name passed through to the wrapper `div`.
   * @defaultValue `""`
   */
  className?: string;
  /**
   * Disables the TextAreaFormGroup.
   * @defaultValue `false`
   */
  disabled?: boolean,
  /**
   * The function called when any changes are made within the TextAreaFormGroup.
   * @defaultValue `() => undefined`
   */
  onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  /**
   * Labels are required for accessibility, but can be hidden visually.
   * @defaultValue `false`
   */
  hideLabel?: boolean;
  /**
   * Needed for accessibility when no other label is associated to the input.
   * See "Hiding labels" above to visually hide the label.
   */
  label: ReactNode;
  /**
   * Used to render content *before* the label, but outside the click-able area.
   * @defaultValue `undefined`
   */
  renderLabelStart?: ReactNode | (() => ReactNode);
  /**
   * Used to render content *after* the label, but outside the click-able area.
   * @defaultValue `undefined`
   */
  renderLabelEnd?: ReactNode | (() => ReactNode);
  /**
   * Label and message will be inlined.
   * @defaultValue `false`
   */
  inline?: boolean;
  /**
   * Some suggested or example information.
   * @defaultValue `""`
   */
  placeholder?: string;
  /**
   * Adds a message below the <textarea>, which will update based on error/success/warning.
   * @defaultValue `""`
   */
  message?: string | RenderMessage;
  /**
   * Specifies the type of message to be displayed (error, warning, success)
   * @defaultValue `undefined`
   */
  statusType?: StatusType;
  /**
   * Use to match container, useful for setting specific widths (by setting width on container).
   * @defaultValue `false`
   */
  fullWidth?: boolean;
  /**
   * Use to access the <textarea> element ref.
   * @defaultValue `() => null`
   */
  inputRef?: Ref<HTMLTextAreaElement>;
  /**
   * *Prop will be deprecated in a future version, use renderLabelEnd with `<HelpTip/>` instead.*
   * Adds an info tooltip with your help message (can pass a string or node).
   * @defaultValue `undefined`
   */
  help?: ReactNode;
  /**
   * A data-id that can be used for writing unit tests.
   * @defaultValue `undefined`
   */
  messageE2EId?: string;
}

export interface TextAreaFormGroupState {
  ephemeralId?: string;
}

const textAreaPropTypes = {
  /**
   * The page unique identifier of the TextArea input.
   */
  id: PT.string.isRequired,
  /**
   * The class name passed through to the wrapper `div`.
   */
  className: PT.string,
  /**
   * Disables the TextAreaFormGroup.
   */
  disabled: PT.bool,
  /**
   * The function called when any changes are made within the TextAreaFormGroup.
   */
  onChange: PT.func,
  /**
   * Labels are required for accessibility, but can be hidden visually.
   */
  hideLabel: PT.bool,
  /**
   * Needed for accessibility when no other label is associated to the input.
   * See "Hiding labels" above to visually hide the label.
   */
  label: PT.oneOfType([
    PT.string,
    PT.node,
  ]).isRequired,
  /**
   * Used to render content *before* the label, but outside the click-able area.
   */
  renderLabelStart: PT.oneOfType([PT.func, PT.node]),
  /**
   * Used to render content *after* the label, but outside the click-able area.
   */
  renderLabelEnd: PT.oneOfType([PT.func, PT.node]),
  /**
   * Label and message will be inlined.
   */
  inline: PT.bool,
  /**
   * Some suggested or example information.
   */
  placeholder: PT.string,
  /**
   * Adds a message below the <textarea>, which will update based on error/success/warning.
   */
  message: PT.oneOfType([PT.string, PT.func]),
  /**
   * Specifies the type of message to be displayed (error, warning, success)
   */
  statusType: PT.oneOf<StatusType>(['error', 'warning', 'success']),
  /**
   * Use to match container, useful for setting specific widths (by setting width on container).
   */
  fullWidth: PT.bool,
  /**
   * Use to access the <textarea> element ref.
   */
  inputRef: PT.func,
  /**
   * *Prop will be deprecated in a future version, use renderLabelEnd with `<HelpTip/>` instead.*
   * Adds an info tooltip with your help message (can pass a string or node).
   */
  help: PT.oneOfType([
    PT.string,
    PT.node,
  ]),
  /**
   * A data-id that can be used for writing unit tests.
   */
  messageE2EId: PT.string,
};

const textAreaDefaultProps = {
  disabled: false,
  onChange: noop,
  hideLabel: false,
  renderLabelStart: undefined,
  renderLabelEnd: undefined,
  inline: false,
  placeholder: '',
  message: '',
  statusType: undefined,
  fullWidth: false,
  help: undefined,
  inputRef: () => null,
  messageE2EId: undefined,
  className: '',
};

export class TextAreaFormGroupComponent extends PureComponent<TextAreaFormGroupProps, TextAreaFormGroupState> {
  static propTypes = textAreaPropTypes;

  static defaultProps = textAreaDefaultProps;

  private inputGroup = createRef<HTMLElement>();

  private styledTextArea;

  constructor(props: TextAreaFormGroupProps) {
    super(props);

    this.state = {
      ephemeralId: undefined,
    };

    /*
     *  Saving a reference to the instance of the function that creates the input element at
     *  constructor time is a performance consideration. We don't want to instantiate a new
     *  render-able every time we run `render()`.
     */
    this.styledTextArea = styled(
      ({
        /*
         * Exclude the props that are only passed to the style. If The props were passed to the
         * react node, they would be attached to the DOM node and throw a warning.
         */
        prefix,
        suffix,
        inputRef,
        ...inputProps
      }) => createElement(
        'textarea',
        {
          ...inputProps,
          ref: inputRef,
        },
        inputProps.children,
      ),
    )`${styleTextAreaMixin}`;
    this.styledTextArea.displayName = 'TextArea';
  }

  componentDidMount() {
    const {
      id,
    } = this.props;

    /*
     * create a ephemeral message Id after mounted without a ID and the validation line has
     * been created
     */
    if (!isString(id)) {
      this.setEphemeralId();
    }
  }

  componentDidUpdate(prevProps: TextAreaFormGroupProps) {
    const {
      id,
    } = this.props;

    /*
     * Deals with the edge case when a id prop is set or cleared when the validation line is
     * visible
     */
    if (id !== prevProps.id) {
      if (!isString(id)) {
        this.setEphemeralId();
      } else {
        this.clearEphemeralId();
      }
    }
  }

  setEphemeralId = () => {
    const { ephemeralId } = this.state;
    // prevent changing the ephemeralId if one was already set
    if (!isString(ephemeralId)) {
      this.setState(() => ({ ephemeralId: createPseudoUID() }));
    }
  }

  clearEphemeralId = () => {
    this.setState(() => ({ ephemeralId: undefined }));
  }

  renderLabelEndWithHelptip = () => {
    const {
      renderLabelEnd,
      help,
    } = this.props;
    return (
      <>
        {isFunction(renderLabelEnd) && renderLabelEnd()}
        <HelpTip position="right">{help}</HelpTip>
      </>
    );
  }

  focusInput = () => {
    if (this.inputGroup.current) {
      const focusable = getFirstFocusableElement(this.inputGroup.current);
      if (focusable instanceof HTMLElement
        && isFunction<(options?: unknown) => void>(focusable.focus)) {
        focusable.focus();
      }
    }
  }

  render() {
    const {
      id,
      className,
      disabled,
      label,
      message,
      messageE2EId,
      statusType,
      fullWidth,
      inline,
      hideLabel,
      renderLabelStart,
      renderLabelEnd,
      inputRef,
      help,
      value,
      taktId,
      taktValue,
      ...rest
    } = this.props;

    const {
      ephemeralId,
    } = this.state;

    const labelId = isString(id) ? undefined : `${ephemeralId}-textarea-label`;
    const messageId = isString(id) ? `${id}-textarea-message` : `${ephemeralId}-textarea-message`;

    return (
      <TaktIdConsumer taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('text-area-form-group')}>
        {({ getDataTaktAttributes }) => (
          <div className={className}>
            {label && (
              <LabelLine
                hidden={hideLabel}
                {...getDataTaktAttributes({ taktIdSuffix: 'label' })}
                renderLabelStart={renderLabelStart}
                renderLabelEnd={
                  /**
                   * Support the `help` prop by folding it into renderLabelEnd
                   */
                  help ? this.renderLabelEndWithHelptip : renderLabelEnd
                }
                {
                /**
                 * When `id` prop is provided, we do not need to do the labelledby work around and
                 * simulate `<label for="...">` click behavior
                 */
                ...(isString(id)
                  ? { labelFor: id }
                  : {
                    labelId,
                    onLabelClick: this.focusInput,
                  })
                }
              >
                {label}
              </LabelLine>
            )}

            <InputGroup
              disabled={disabled}
              fullWidth={fullWidth}
              inline={inline}
              $statusType={statusType}
              inputGroupRef={this.inputGroup}
            >
              {
                /*
                * Using the non-JSX `React.createElement()` lets us choose between the named DOM
                * element and a function that returns a node, without adding additional flow control.
                */
                createElement(
                  this.styledTextArea,
                  {
                    ...getDataTaktAttributes(),
                    ...rest,
                    /**
                    * When `id` prop is provided, we do not need to do the labelledby work around
                    */
                    ...(isString(id) ? { id } : {}),
                    ...(isString(labelId) ? { 'aria-labelledby': labelId } : {}),

                    /**
                     * Add a 'aria-describedby' when any message is applied to the textarea
                     */
                    ...((message) ? { 'aria-describedby': messageId } : {}),
                    /*
                    * Error get a `'aria-invalid="true"`
                    */
                    ...((statusType === 'error') ? { 'aria-invalid': true } : {}),
                    value,
                    inputRef,
                    disabled,
                    $statusType: statusType,
                  },
                  null, /* children are null when assuming a closed tag like `<TextArea />` */
                )
              }
            </InputGroup>

            { (!!message) && (
              <ValidationLine
                role="alert"
                aria-live="assertive"
                id={messageId}
                {...getDataTaktAttributes({ taktIdSuffix: 'message' })}
              >
                {typeof message === 'function'
                  ? message(value)
                  : (
                    <TextAreaValidationMessage
                      messageType={statusType}
                      message={message}
                      data-e2e-id={messageE2EId}
                    />
                  )}
              </ValidationLine>
            )}
          </div>
        )}
      </TaktIdConsumer>
    );
  }
}

const TextAreaFormGroup = styled(TextAreaFormGroupComponent)`
  ${({ inline }) => (inline ? formGroupRowStyleMixin : formGroupColumnStyleMixin)}
  ${isMobile(formGroupColumnStyleMixin)}
`;

TextAreaFormGroup.propTypes = textAreaPropTypes;

TextAreaFormGroup.defaultProps = textAreaDefaultProps;

TextAreaFormGroup.displayName = 'TextAreaFormGroup';

export default TextAreaFormGroup;
