import styled, { css } from 'styled-components';
import Text from '../../Text';
import isMobile from '../../theme/style-mixins/isMobile/isMobile';
import focusOutlineMixin from '../../FocusIndicator/styleMixins/focusOutline';
import Box, { BoxProps } from '../../Box/Box';
import {
  basicMixin as checkboxBasicMixin,
  checkedMixin as checkboxCheckedMixin,
  disabledMixin as checkboxDisabledMixin,
  CheckboxInputProps,
} from '../../Checkbox/Checkbox';
import type { TileDirection, TileTitleFontSize } from '../types';

export const Main = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
  padding: ${({ theme }) => theme.spacing.medium};
`;

export const Body = styled(Text).attrs({ type: 'p' })`
  text-align: start;
  font-size: ${({ theme }) => theme.typography.size.small};
  ${isMobile(css`
    font-size: ${({ theme }) => theme.typography.mobile.size.small};
  `)}

  :has(+ *) {
    margin-block-end: ${({ theme }) => theme.spacing.mini};
  }
`;

interface ChildProps {
  $direction: TileDirection;
  $hideStartBorder?: boolean;
}
const rowChildMixin = css`
  border-inline-start: 1px solid ${({ theme }) => theme.button.toggleArea.borderColor};
  border-inline-end: 1px solid ${({ theme }) => theme.button.toggleArea.borderColor};
`;
const columnChildMixin = css`
  border-block-start: 1px solid ${({ theme }) => theme.button.toggleArea.borderColor};
  border-block-end: 1px solid ${({ theme }) => theme.button.toggleArea.borderColor};
`;
const childMixin = css<ChildProps>`
  ${({ $direction }) => ($direction === 'row' ? rowChildMixin : columnChildMixin)}
`;

export interface TitleProps {
  $titleFontSize?: TileTitleFontSize;
}
export const Title = styled(Text).attrs({ withMargin: false, type: 'p' })<TitleProps>`
  text-align: start;
  :has(+ *) {
    margin-block-end: ${({ $titleFontSize, theme }) => ($titleFontSize === 'large' ? theme.spacing.mini : theme.spacing.small)};
  }

  ${({ theme, $titleFontSize }) => (theme.typography[$titleFontSize === 'large' ? 'h4' : 'h5'])}
  ${isMobile(css<TitleProps>`
    ${({ theme, $titleFontSize }) => (theme.typography.mobile[$titleFontSize === 'large' ? 'h4' : 'h5'])}
  `)}
`;

export interface ContainerProps {
  $checked?: boolean;
  $disabled?: boolean;
  $direction?: TileDirection;
  $focusVisible: boolean;
}

const rowContainerChildrenMixin = css<ChildProps>`
  border-inline-end: none;
`;
const columnContainerChildrenMixin = css<ChildProps>`
  border-block-end: none;
`;
const containerChildrenMixin = css<ChildProps>`
  ${({ $direction }) => ($direction === 'row' ? rowContainerChildrenMixin : columnContainerChildrenMixin)}
`;

const containerChildrenBorderRadiusMixin = css<ChildProps>`
  ${({ theme }) => `calc(${theme.button.toggleArea.borderRadius} - 1px)`}
`;

const rowContainerFirstChildMixin = css<ChildProps>`
  border-start-start-radius: ${containerChildrenBorderRadiusMixin};
  border-end-start-radius: ${containerChildrenBorderRadiusMixin};
  border-inline-start: none;
`;
const columnContainerFirstChildMixin = css<ChildProps>`
  border-start-start-radius: ${containerChildrenBorderRadiusMixin};
  border-start-end-radius: ${containerChildrenBorderRadiusMixin};
  border-block-start: none;
`;
const containerFirstChildMixin = css<ChildProps>`
  ${({ $direction }) => ($direction === 'row' ? rowContainerFirstChildMixin : columnContainerFirstChildMixin)}
`;

const rowContainerLastChildMixin = css<ChildProps>`
  border-start-end-radius: ${containerChildrenBorderRadiusMixin};
  border-end-end-radius: ${containerChildrenBorderRadiusMixin};
`;
const columnContainerLastChildMixin = css<ChildProps>`
  border-end-start-radius: ${containerChildrenBorderRadiusMixin};
  border-end-end-radius: ${containerChildrenBorderRadiusMixin};
`;
const containerLastChildMixin = css<ChildProps>`
  ${({ $direction }) => ($direction === 'row' ? rowContainerLastChildMixin : columnContainerLastChildMixin)}
`;

const rowChildHideBorderMixin = css<ChildProps>`
  ${({ $hideStartBorder }) => $hideStartBorder && css`
    border-inline-start: none;
  `}
`;
const columnChildHideBorderMixin = css<ChildProps>`
  ${({ $hideStartBorder }) => $hideStartBorder && css`
    border-block-start: none;
  `}
`;
const childHideBorderMixin = css<ChildProps>`
  ${({ $direction }) => ($direction === 'row' ? rowChildHideBorderMixin : columnChildHideBorderMixin)}
`;

const focusMixin = css`
  :has(:focus-visible) {
    ${focusOutlineMixin}
  }

  @supports not selector(:focus-visible) {
    ${focusOutlineMixin}
  }
`;

export const Container = styled.div<ContainerProps>`
  overflow-x: auto; // @TODO: scrollbar style

  ${({ theme }) => theme.typography.base};
  background-color: ${({ theme }) => theme.button.toggleArea.bg};
  border: 1px solid ${({ theme }) => theme.button.toggleArea.borderColor};
  border-radius: ${({ theme }) => theme.button.toggleArea.borderRadius};
  color: ${({ theme }) => theme.button.toggleArea.color};
  user-select: auto;

  display: flex;

  @media (prefers-reduced-motion: no-preference) {
    transition: color 100ms ease,border-color 100ms ease;
  }

  ${isMobile(css`
    ${({ theme }) => theme.typography.mobile.base};
    flex: 1;
  `)}

  ${({ $checked }) => ($checked && css`
    border: 1px solid ${({ theme }) => theme.button.toggleArea.borderColorActive};
    box-shadow: 0 0 0 1px ${({ theme }) => theme.button.toggleArea.borderColorActive};
  `)}

  ${({ $disabled }) => ($disabled && css`
    pointer-events: all;
    cursor: not-allowed;
    outline: none;
    opacity: 1;
    color: ${({ theme }) => theme.typography.color.tertiary};
  `)}

  flex-direction: ${({ $direction }) => ($direction === 'row' ? 'row' : 'column')};

  ${({ $focusVisible }) => ($focusVisible && focusMixin)}
`;

export const SubText = styled(Text).attrs({ type: 'p' })`
  text-align: start;
  font-size: ${({ theme }) => theme.typography.size.small};
  ${isMobile(css`
    font-size: ${({ theme }) => theme.typography.mobile.size.small};
  `)}
  color: ${({ theme }) => theme.palette.gray[700]};
`;

export const IconWrapper = styled.div`
  margin-inline-end: ${({ theme }) => theme.spacing.small};
`;

export interface CheckboxProps extends CheckboxInputProps {
  $checked?: boolean;
  $disabled?: boolean;
}

export const Checkbox = styled('div')<CheckboxProps>`
  ${checkboxBasicMixin}
  appearance: none;
  margin: 0;
  margin-block-start: 2px;
  margin-inline-end: ${({ theme }) => theme.spacing.small};

  ${({ $checked }) => $checked && checkboxCheckedMixin}

  ${({ $disabled }) => $disabled && checkboxDisabledMixin}
`;

export interface ChildBoxProps extends ChildProps, BoxProps<'div'> {}

export const ChildBox = styled(Box)<ChildBoxProps>`
  border-radius: initial;
  ${childMixin}
  ${childHideBorderMixin}

  ${containerChildrenMixin}
  :first-of-type {
    ${containerFirstChildMixin}
  }
  :last-of-type {
    ${containerLastChildMixin}
  }
`;
