import React from 'react';
import { useDropdownIsMobile } from './DropdownIsMobileContext';
import DesktopContainer, { DesktopContainerProps } from './DropdownDesktopContainer';
import MobileContainer, { MobileContainerProps } from './DropdownMobileContainer';

export interface DropdownContainerProps extends MobileContainerProps, DesktopContainerProps {
  isOpen: boolean;
  trigger: DesktopContainerProps['trigger'];
  onClose: () => void;
  name: string;
  anchorEl: MobileContainerProps['anchorEl'];
  onChange: MobileContainerProps['onChange'];
}

function DropdownContainer({
  isOpen,
  onClose,
  secondaryViewCloseButtonLabel,
  children,
  shadowRoot,
  trigger,
  anchorEl,
  preRender,
  postRender,
  selectedValue,
  onChange,
  noWrap,
  name,
  focusSelected,
  forceOpen,
}: DropdownContainerProps) {
  const isMobile = useDropdownIsMobile();

  if (isMobile) {
    return (
      <MobileContainer
        isOpen={isOpen}
        onClose={onClose}
        anchorEl={anchorEl}
        secondaryViewCloseButtonLabel={secondaryViewCloseButtonLabel}
        preRender={preRender}
        postRender={postRender}
        trigger={trigger}
        name={name}
        selectedValue={selectedValue === undefined ? undefined : String(selectedValue)}
        onChange={onChange}
      >
        {children}
      </MobileContainer>
    );
  }

  return (
    <DesktopContainer
      isOpen={isOpen}
      onClose={onClose}
      shadowRoot={shadowRoot}
      trigger={trigger}
      preRender={preRender}
      selectedValue={selectedValue}
      onChange={onChange}
      noWrap={noWrap}
      postRender={postRender}
      focusSelected={focusSelected}
      anchorEl={anchorEl}
      forceOpen={forceOpen}
    >
      {children}
    </DesktopContainer>
  );
}

export default DropdownContainer;
