import React, {
  useCallback,
  useLayoutEffect,
  ReactElement,
  MouseEvent,
  KeyboardEvent,
} from 'react';
import {
  focusFirstElement,
  isEventFromWithinElement,
  isFunction,
  keyboardKeynames as keys,
  noop,
  trapTabFocus,
} from '@amzn/storm-ui-utils';
import PopperContentContainer from '../Popper/PopperContentContainer/PopperContentContainer';
import PopperContainer from '../Popper/PopperContainer';
import useInlinePopper, { InlinePopperOptions } from '../Popper/useInlinePopper';
import type { PopperProps } from '../Popper/Popper';
import type { InlinePopper } from '../Popper/useInlinePopper';
import { PopperContentContainerWrapper, OverflowWrapper } from './Dropdown.styles';
import type { PopperContentContainerWrapperProps } from './Dropdown.styles';

export interface DropdownPopperProps extends Pick<PopperContentContainerWrapperProps, '$minWidth' | '$fullWidth' | '$noMaxHeight'>, Pick<PopperProps,
  'isOpen' |
  'onKeyDown' |
  'popperRef' |
  'shadowRoot' |
  'children'
> {
  /**
   * @defaultValue `0`
   */
  offsetDistance?: InlinePopperOptions['offsetSkidding'];
  /**
   * @defaultValue `0`
   */
  offsetSkidding?: InlinePopperOptions['offsetSkidding'];
  onClose: PopperProps['onCloseButtonClick'];
  popperElementDidMount: (popperElement: HTMLElement) => void;
  trigger: (triggerProps?: { setTriggerElement?: InlinePopper['setTriggerElement'] }) => ReactElement;
  /**
   * @defaultValue `false`
   */
  focusFirstElementOnPopoverExited?: boolean;
  /**
   * @defaultValue `false`
   */
  closePopperIfTriggerClicked?: boolean;
}

const DropdownPopper = ({
  isOpen,
  onClose,
  offsetDistance,
  offsetSkidding,
  $minWidth,
  $fullWidth,
  $noMaxHeight,
  onKeyDown,
  popperElementDidMount,
  popperRef,
  children,
  shadowRoot,
  trigger,
  focusFirstElementOnPopoverExited,
  closePopperIfTriggerClicked,
}: DropdownPopperProps) => {
  const {
    attributes,
    styles,
    triggerElement,
    popperElement,
    setArrowElement,
    setPopperElement,
    setTriggerElement,
    update,
  } = useInlinePopper({
    offsetDistance,
    offsetSkidding,
    placement: 'bottom-start',
    enableFlip: true,
    preventOverflow: {
      mainAxis: true,
    },
    withArrow: false,
    boundaryRef: undefined,
    strategy: 'absolute',
  });

  /**
   * Click on outside popover and trigger should close the popover
   */
  const handleDocumentMousedown = useCallback((event: Event) => {
    if (typeof onClose === 'function') {
      const isNotWithinPopper = !isEventFromWithinElement(event, popperElement);
      const isNotWithinTrigger = !isEventFromWithinElement(event, triggerElement) || closePopperIfTriggerClicked;
      if (isNotWithinPopper && isNotWithinTrigger) {
        onClose(event as unknown as MouseEvent<HTMLButtonElement>);
      }
    }
  }, [closePopperIfTriggerClicked, onClose, popperElement, triggerElement]);

  /**
   * Handles keyboard KeyDown input when the popover surface is in focus.
   */
  const handleKeyDown = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
    const { key } = event;
    if (popperElement) {
    // Trap the focus of the browser inside the popover element. Keep the user in this element
    // and allow them to tab through content until the user closes the <Popover />.
      trapTabFocus(event, popperElement, false);
    }

    if (key === keys.SPACE) {
      event.stopPropagation();
    }
    if (isFunction(onKeyDown)) {
      onKeyDown(event);
    }
  }, [onKeyDown, popperElement]);

  /**
   * Function called once the <Transition /> component is in the entered state.
   *
   * If we are showing focus because we have had some keyboard input, we should set
   * focus to the Popover element.
   */
  const handlePopoverEntered = useCallback(() => {
    if (popperElement) {
      focusFirstElement(popperElement);
    }
    // eslint-disable-next-line max-len
    if (popperElementDidMount && isFunction(popperElementDidMount) && popperElement) {
      popperElementDidMount(popperElement);
    }
  }, [popperElementDidMount, popperElement]);

  const handlePopoverEntering = useCallback((node: unknown, isAppearing: boolean) => {
    if (!isAppearing && update !== null) {
      update();
    }
  }, [update]);

  /**
   * Function called once the <Transition /> component is in the exited state.
   */
  const handlePopoverExited = useCallback(() => {
    if (focusFirstElementOnPopoverExited && triggerElement) {
      focusFirstElement(triggerElement);
    }
  }, [focusFirstElementOnPopoverExited, triggerElement]);

  // change of $minWidth means the position of the trigger might also changed
  useLayoutEffect(() => {
    if (update) {
      update();
    }
  }, [$minWidth, update]);

  return (
    <>
      {trigger({ setTriggerElement })}
      <PopperContainer
        role="listbox"
        focusableBody
        attributes={attributes}
        disablePortal={false}
        handleCloseClicked={noop}
        handleKeyDown={handleKeyDown}
        handlePopoverEntered={handlePopoverEntered}
        handlePopoverEntering={handlePopoverEntering}
        handlePopoverExited={handlePopoverExited}
        isOpen={isOpen}
        onDocumentMousedown={handleDocumentMousedown}
        position="bottom"
        setArrowElement={setArrowElement}
        setPopperElement={setPopperElement}
        styles={styles}
        type="light"
        withCloseButton={false}
        withArrow={false}
        popperRef={popperRef}
        shadowRoot={shadowRoot}
        transitionDuration={100}
        popoverType="Dropdown"
      >
        <PopperContentContainerWrapper $minWidth={$minWidth} $fullWidth={$fullWidth} $noMaxHeight={$noMaxHeight}>
          <PopperContentContainer
            handleCloseClicked={noop}
            padding="none"
            type="light"
            withCloseButton={false}
          >
            <OverflowWrapper $noMaxHeight={$noMaxHeight}>
              {children}
            </OverflowWrapper>
          </PopperContentContainer>
        </PopperContentContainerWrapper>
      </PopperContainer>
    </>
  );
}
DropdownPopper.defaultProps = {
  offsetDistance: 0,
  offsetSkidding: 0,
  focusFirstElementOnPopoverExited: false,
  closePopperIfTriggerClicked: false,
}

export default DropdownPopper;
