import React, { FC, PropsWithChildren } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownProps, TaktProps } from '@amzn/storm-ui';
import { noop } from '@amzn/storm-ui-utils';
import WithFilterComponent, { FilterProps } from './withFilter';

export interface FilterDropdownProps<T = any> extends DropdownProps, FilterProps<T>, TaktProps {
  /**
   * Use this to provide a screen reader label for the search <input> clear button.
   * @defaultValue `"Clear search terms"`
   */
  clearButtonLabel?: FilterProps['clearButtonLabel'];
  /**
   * Labels are required for accessibility but can be visually hidden using this prop.
   * @defaultValue `undefined`
   */
  hideSearchInputLabel?: FilterProps['hideSearchInputLabel'];
  /**
   * Used to render dropdown in SecondaryView on mobile device.
   * @defaultValue `false`
   */
  mobileFullScreen?: FilterProps['mobileFullScreen'];
  /**
   * The list of items to be displayed in the dropdown.
   */
  items: FilterProps['items'];
  /**
   * Callback that returns results of fuzzy search on each input state change.
   * @defaultValue `() => undefined`
   */
  onFilterChange?: FilterProps['onFilterChange'];
  /**
   * Callback that is called when the clear button is clicked on the filter input.
   * @defaultValue `() => undefined`
   */
  onFilterClear?: FilterProps['onFilterClear'];
  /**
   * Options for customizing [Fuse.js](https://fusejs.io/). Sensible defaults are already declared. Define the object properties
   * you want to search with using the `keys` option.
   * @defaultValue `{
  shouldSort: true,
  threshold: 0.6,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ['label', 'value'],
  }`
   */
  options?: FilterProps['options'];
  /**
   * Placeholder text for the search <input> element.
   * @defaultValue `""`
   */
  placeholder?: FilterProps['placeholder'];
  /**
   * Unique id for the search <input> element.
   */
  searchInputId: FilterProps['searchInputId'];
  /**
   * Custom label for the search <input> element.
   * @defaultValue `undefined`
   */
  searchInputLabel?: FilterProps['searchInputLabel'];
  /**
   * Used to render dropdown button with no background.
   * @defaultValue `false`
   */
  transparentButton?: FilterProps['transparentButton'];
  /**
   * By default, the text input will clear and onFilterClear will be called when opening the dropdown.
   * Enabling this prop will circumvent this behavior.
   * @defaultValue `false`
   */
  persistFilterState?: FilterProps['persistFilterState'];

  /**
   * Callback that is called when the filter dropdown is opened.
   * @defaultValue `() => undefined`
   */
  onOpen?: DropdownProps['onOpen'];
  /**
   * Render prop for rendering components into the DOM before the filter dropdown.
   * @defaultValue `() => null`
   */
  preRender?: DropdownProps['preRender'];
  /**
   * Accepts elements to be rendered inside the close button in the SecondaryView's header.
   * @defaultValue `"Done"`
   */
  secondaryViewCloseButtonLabel?: DropdownProps['secondaryViewCloseButtonLabel'];
}

const FilterDropdownBody = WithFilterComponent<DropdownProps>(Dropdown);

const FilterDropdown: FC<PropsWithChildren<FilterDropdownProps>> = props => (
  <FilterDropdownBody focusSelected={false} {...props} />
);

FilterDropdown.propTypes = {
  /**
   * Unique id for the search <input> element.
   */
  searchInputId: PropTypes.string.isRequired,
  /**
   * Placeholder text for the search <input> element.
   */
  placeholder: PropTypes.string,
  /**
   * Callback that returns results of fuzzy search on each input state change.
   */
  onFilterChange: PropTypes.func,
  /**
   * Callback that is called when the clear button is clicked on the filter input.
   */
  onFilterClear: PropTypes.func,
  /**
   * Callback that is called when the filter dropdown is opened.
   */
  onOpen: PropTypes.func,
  /**
   * The list of items to be displayed in the dropdown.
   */
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * Use this to provide a screen reader label for the search <input> clear button.
   */
  clearButtonLabel: PropTypes.string,
  /**
   * Options for customizing [Fuse.js](https://fusejs.io/). Sensible defaults are already declared. Define the object properties
   * you want to search with using the `keys` option.
   */
  options: PropTypes.shape({
    shouldSort: PropTypes.bool,
    threshold: PropTypes.number,
    location: PropTypes.number,
    distance: PropTypes.number,
    maxPatternLength: PropTypes.number,
    minMatchCharLength: PropTypes.number,
    keys: PropTypes.arrayOf(PropTypes.string),
  }) as React.Validator<Fuse.FuseOptions<any>>,
  /**
   * Render prop for rendering components into the DOM before the filter dropdown.
   */
  preRender: PropTypes.func,
  /**
   * Labels are required for accessibility but can be visually hidden using this prop.
   */
  hideSearchInputLabel: PropTypes.bool,
  /**
   * Custom label for the search <input> element.
   */
  searchInputLabel: PropTypes.string,
  /**
   * Used to render dropdown button with no background.
   */
  transparentButton: PropTypes.bool,
  /**
   * Used to render dropdown in SecondaryView on mobile device.
   */
  mobileFullScreen: PropTypes.bool,
  /**
   * Accepts elements to be rendered inside the close button in the SecondaryView's header.
   */
  secondaryViewCloseButtonLabel: PropTypes.node,
  /**
   * By default, the text input will clear and onFilterClear will be called when opening the dropdown.
   * Enabling this prop will circumvent this behavior.
   */
  persistFilterState: PropTypes.bool,
};

FilterDropdown.defaultProps = {
  placeholder: '',
  onFilterChange: noop,
  onFilterClear: noop,
  onOpen: noop,
  preRender: () => null,
  clearButtonLabel: 'Clear search terms',
  options: {
    shouldSort: true,
    threshold: 0.6,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['label', 'value'],
  },
  hideSearchInputLabel: undefined,
  searchInputLabel: undefined,
  transparentButton: false,
  mobileFullScreen: false,
  secondaryViewCloseButtonLabel: 'Done',
  persistFilterState: false,
};

export default FilterDropdown;
