/* eslint-disable no-restricted-syntax, id-length */
import React, {
  ChangeEvent,
  ChangeEventHandler,
  KeyboardEvent,
  ReactNode,
  RefObject,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
  DragEvent,
  useLayoutEffect,
} from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { AdvertisingLocale, MergeElementProps, keyboardKeynames } from '@amzn/storm-ui-utils-v3';
import { IntlProvider, useIntl } from '@amzn/storm-ui-intl-v3';
import { inputFocusStyles } from '../InputFormGroup/InputFormGroup.styles';
import { styleTextAreaMixin } from '../TextAreaFormGroup/TextAreaFormGroup';
import AttachmentPill from './AttachmentPill';
import ValidationLine from '../FormGroup/ValidationLine';
import { InlineMessage } from '../Text';
import FileAttachmentButton from './FileAttachmentButton';
import SendButton from './SendButton';
import { getTranslationComponent } from '../i18n/ChatBoxInputTranslation';
import { Theme, ThemeProvider } from '../theme';
import {
  BASE_INPUT_HEIGHT,
  TOOLTIP_DELAY_MS,
  MAX_ATTACHMENTS,
  MAX_SIZE_MB,
  MAX_ROWS,
  INPUT_PADDING,
} from './constants';
import {
  TaktIdConsumer, createStormTaktId,
} from '../TaktIdContext';
import { TaktProps } from '../types/TaktProps';

const overrideMouseTimeout = (theme: Theme) => ({
  ...theme,
  tooltip: {
    ...theme.tooltip,
    mouseEnterTimeout: TOOLTIP_DELAY_MS,
    mouseLeaveTimeout: 0,
  },
});

function getContainerBackgroundColor(theme: Theme, disabled?: boolean, drag?: boolean) {
  if (disabled) {
    return theme.form.input.disabled.bg;
  }
  if (drag) {
    return theme.palette.blue[50];
  }
  return theme.form.input.bg;
}

interface ContainerProps {
  disabled?: boolean;
  $drag?: boolean;
}

const Container = styled('div')<ContainerProps>`
  border: ${({ theme }) => theme.form.input.border};
  border-color: ${({ theme }) => theme.form.input.borderColor};
  border-style: ${({ $drag }) => ($drag ? 'dashed' : 'solid')}
  box-shadow: ${({ theme }) => theme.form.input.boxShadow};
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.form.input.radius};
  background: ${({ theme, disabled, $drag }) => getContainerBackgroundColor(theme, disabled, $drag)};
  color: ${({ theme, disabled }) => (disabled ? theme.form.input.disabled.color : theme.form.input.color)};

  :focus-within {
    ${inputFocusStyles}
  }

  border-style: ${({ $drag }) => ($drag ? 'dotted' : 'solid')}
`;

const InputGroup = styled(({ children, inputGroupRef }) => (
  <div ref={inputGroupRef}>{children}</div>
))`
  border: none;
  box-shadow: ${({ theme }) => theme.form.input.boxShadow};
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.form.input.radius};
  min-width: auto;
  display: flex;

  pointer-events: ${({ $drag }) => ($drag ? 'none' : 'auto')};

  > textarea {
    /* needed for AUI overriding */
    ${styleTextAreaMixin}
    pointer-events: ${({ $drag }) => ($drag ? 'none' : 'auto')};
  }
`;

const AttachmentRow = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: ${({ theme }) => theme.spacing.micro};
  padding: ${({ theme }) => `${theme.spacing.micro} ${theme.spacing.small}`};
  flex-wrap: wrap;
`;

const ActionBarWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing.micro} ${theme.spacing.mini}`};
`;

const ActionBar = styled('div')`
  width: 100%;
`;

const StyledTextArea = styled('textarea')`
  ${styleTextAreaMixin}
  resize: none;
  max-height: ${(BASE_INPUT_HEIGHT * MAX_ROWS) - MAX_ROWS - INPUT_PADDING}px;
  overflow: auto;
  box-sizing: border-box;
`;

const MessageContainer = styled('div')`
  margin-top: ${({ theme }) => theme.spacing.micro};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.micro};
`;

const ChatBoxValidationMessage = styled(InlineMessage)`
  margin-top: 0;
  margin-bottom: 0;
`;

export interface ChatBoxInputProps extends TaktProps, Omit<MergeElementProps<'textarea'>, 'ref'|'id'|'onSubmit'> {
  /** The unique identifier of the textarea */
  id: string;
  /** The initial textarea value added by the user */
  value?: string;
  /** The initial attachments added by the user. */
  attachments?: File[];
  /** Denotes if the component should be disabled. */
  disabled?: boolean,
  /** The function called on input change. */
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  /** A placeholder value for the textarea. */
  placeholder?: string;
  /**  Use to access the textarea container ref. */
  inputRef?: RefObject<HTMLTextAreaElement>;
  /** The locale string. */
  locale?: AdvertisingLocale;
  /** The function called when a file is attached. */
  onAttach?: (event: SyntheticEvent, attachments: File[]) => void;
  /** The function called when an attachment is closed. */
  onAttachmentClose?: (file: File, index: number) => void;
  /** The function called when the Send button is clicked. */
  onSend?: (text: string, attachments?: File[]) => void;
  /** The function used to render a custom action bar. */
  renderActionBar?: () => ReactNode;
}

const ChatBox = ({
  id,
  value = '',
  attachments: inputAttachments,
  disabled,
  placeholder,
  inputRef,
  locale,
  onChange,
  onAttach,
  onSend,
  renderActionBar,
  taktId,
  taktValue,
  onAttachmentClose,
  ...rest
}: ChatBoxInputProps) => {
  const [isActiveDrag, setActiveDrag] = useState(false);
  const [error, setError] = useState<string>('');

  const [inputValue, setInputValue] = useState<string>(value);

  const getInitialAttachments = () => {
    const initialAttachments = [];
    if (inputAttachments) {
      for (const att of inputAttachments) {
        const uniqueId = uuidv4();
        initialAttachments.push({ file: att, id: uniqueId });
      }
    }
    return initialAttachments;
  }
  const [attachments, setAttachments] = useState<{ file: File; id: string; }[]>(getInitialAttachments);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const { formatMessage } = useIntl();

  useLayoutEffect(() => {
    /** Adjust size at render */
    resize();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const resize = useCallback((clearInput = false) => {
    if (textAreaRef.current) {
      if (clearInput) {
        textAreaRef.current.innerText = '';
        textAreaRef.current.style.height = `${BASE_INPUT_HEIGHT}px`;
        return;
      }
      // removing the height allows us to validate the scroll height
      textAreaRef.current.style.removeProperty('height');
      if (textAreaRef.current.scrollHeight > BASE_INPUT_HEIGHT) {
        const updatedHeight = textAreaRef.current.scrollHeight;
        textAreaRef.current.style.height = `${updatedHeight}px`;
      } else {
        textAreaRef.current.style.height = `${BASE_INPUT_HEIGHT}px`;
      }
    }
  }, []);

  const handleChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    onChange?.(event);
    setInputValue(event.target.value);
    resize();
  }, [onChange, resize]);

  const handleSend = useCallback(() => {
    const files = attachments.map(attachment => attachment.file);
    onSend?.(inputValue, files);
    setInputValue('');
    setAttachments([]);
    setError('');
    resize(true);
  }, [attachments, inputValue, onSend, resize]);

  const handleKeydown = useCallback((event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === keyboardKeynames.ENTER) {
      event.preventDefault();
      handleSend();
    }
  }, [handleSend]);

  const handleAttachmentClose = useCallback((file: File, index: number) => {
    const filteredAttachments = attachments.filter((_att, attachmentIndex) => attachmentIndex !== index);
    setAttachments(filteredAttachments);

    if (filteredAttachments.length <= MAX_ATTACHMENTS) {
      setError('');
    }

    onAttachmentClose?.(file, index);
  }, [attachments, onAttachmentClose]);

  const handleFileUpload = useCallback((event: SyntheticEvent, fileList: File[]) => {
    setError('');

    const validFiles = [];
    let attachmentCount = attachments.length;

    for (const file of fileList) {
      // Cannot attach more than 6 files
      if (attachmentCount >= MAX_ATTACHMENTS) {
        setError(
          formatMessage({ id: 'attachment-count-error' }, {
            n: MAX_ATTACHMENTS,
          }),
        );
        break;
      }

      // File cannot be larger than 5MB
      if (file.size <= MAX_SIZE_MB) {
        const uuid = uuidv4();
        validFiles.push({ file, id: uuid });

        attachmentCount += 1;
      } else {
        setError(
          formatMessage({ id: 'attachment-size-error' }, {
            attachment: file.name,
          }),
        );
      }
    }

    if (validFiles.length) {
      setAttachments([...attachments, ...validFiles]);
      onAttach?.(event, fileList);
    }
  }, [attachments, formatMessage, onAttach]);

  useEffect(() => {
    if (attachments.length) {
      const attachmentIndex = attachments.length - 1;
      const attachmentToFocus = `${attachments[attachmentIndex].file.name}-${attachmentIndex}`;
      document.getElementById(attachmentToFocus)?.focus();
    }
  }, [attachments]);

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    setActiveDrag(true);
    event.preventDefault();
  };

  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    setActiveDrag(false);
    event.preventDefault();
  };

  const handleDrop = useCallback((event: ChangeEvent<HTMLInputElement> & DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setActiveDrag(false);
    const fileList = (event.type === 'drop')
      ? event.dataTransfer.files
      : event.target.files as FileList;
    const fileNames = [...fileList].map(file => file);
    handleFileUpload(event, fileNames);
  }, [handleFileUpload]);

  return (
    <TaktIdConsumer
      taktId={taktId}
      taktValue={taktValue}
      fallbackId={
        createStormTaktId('chat-box-input')
      }
    >
      {({ getDataTaktAttributes }) => (
        <>
          <Container
            id={id}
            disabled={disabled}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            $drag={isActiveDrag}
          >
            <InputGroup inputGroupRef={inputRef}>
              <StyledTextArea
                {...rest}
                {...getDataTaktAttributes({ taktIdSuffix: 'text-area' })}
                id={`${id}-textarea`}
                ref={textAreaRef}
                value={inputValue}
                placeholder={placeholder}
                onChange={handleChange}
                onKeyDown={handleKeydown}
                disabled={disabled}
                rows={1}
              />
            </InputGroup>
            {attachments.length ? (
              <AttachmentRow>
                {attachments.map((attachment, index) => (
                  <AttachmentPill
                    key={`${attachment.file.name}-${attachment.id}`}
                    attachment={attachment.file}
                    onClose={() => handleAttachmentClose(attachment.file, index)}
                  />
                ))}
              </AttachmentRow>
            ) : null}
            <ActionBarWrapper>
              <ActionBar>
                {renderActionBar
                  ? renderActionBar()
                  : (
                    <FileAttachmentButton
                      id={`${id}-file-upload-button`}
                      onChange={handleFileUpload}
                      disabled={disabled}
                    />
                  )}
              </ActionBar>
              <SendButton
                onClick={handleSend}
                disabled={disabled}
                isActive={!!attachments.length || !!inputValue.length}
              />
            </ActionBarWrapper>
          </Container>
          <MessageContainer>
            {
              error && (
                <ValidationLine
                  role="alert"
                  aria-live="assertive"
                  id={`${id}-chatbox-error`}
                >
                  <ChatBoxValidationMessage
                    messageType="error"
                    message={error}
                  />
                </ValidationLine>
              )
            }
          </MessageContainer>
        </>
      )}
    </TaktIdConsumer>
  );
};

const ChatBoxInput = ({
  locale,
  ...rest
}: ChatBoxInputProps) => {
  const translations = getTranslationComponent(locale);
  return (
    <IntlProvider translations={translations}>
      <ThemeProvider theme={overrideMouseTimeout}>
        <ChatBox {...rest} />
      </ThemeProvider>
    </IntlProvider>
  );
};

export default ChatBoxInput;
