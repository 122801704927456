import React, {
  ChangeEvent,
  FunctionComponent,
  ReactNode,
  SyntheticEvent,
  useRef,
} from 'react';
import PT from 'prop-types';
import styled from 'styled-components';
import { noop, MergeElementProps } from '@amzn/storm-ui-utils';
import FileUploadContext from '../FileUploadContext';
import UploadButton from './FileUploadButton';
import type { IconDefinition } from '../../Icon/types';
import { ButtonProps } from '../../Button/Button';
import { DataTaktAttributes } from '../../TaktIdContext';

export interface FileUploadButtonProps extends Omit<MergeElementProps<'input'>, 'accept'|'multiple'|'name'|'ref'|'tabIndex'|'type'|'onChange'> {
  /**
   * Specifies allowed file types
   * @defaultValue `undefined`
   * */
  accept?: string[];
  /**
   * Rendered list of uploader descriptions
   * @defaultValue `undefined`
   * */
  description?: ReactNode;
  /**
   * Specifies if the control is disabled
   * @defaultValue `false`
   * */
  disabled?: boolean;
  /**
   * Text displayed in Alert component when an error occurs
   * @defaultValue `""`
   * */
  errorText?: string;
  /**
   * Specifies if the control takes full width of parent element
   * @defaultValue `false`
   * */
  fullWidth?: boolean;
  /** ID for file input */
  inputId: string;
  /** Text displayed as Button component label */
  label: string;
  /**
   * Specifies that the control will accept one or more values
   * @defaultValue `false`
   * */
  multiple?: boolean;
  /**
   * Name of the form control
   * @defaultValue `""`
   * */
  name?: string;
  /**
   * Callback function called when file(s) are selected from file system
   * @defaultValue `() => undefined`
   * */
  onChange?: (event : SyntheticEvent, files: File[]) => void;
  /**
   * Callback function called when Alert close button is selected
   * @defaultValue `() => undefined`
   * */
  onCloseAlert?: () => void;
  /** Should the label be hidden in the button */
  hideLabel?: boolean;
  /** The icon to display within the button */
  icon?: IconDefinition | string;
  /** Props to be passed to the FileUploadButton  */
  buttonProps?: ButtonProps & DataTaktAttributes;
  /**
   * @defaultValue `false`
   */
  small?: boolean;
  /**
   * @defaultValue `false`
   */
  primary?: boolean;
  /**
   * @defaultValue `false`
   */
  transparent?: boolean;
}

const Input = styled('input')`
  display: none;
`;

const FileUploadButton: FunctionComponent<FileUploadButtonProps> = ({
  accept,
  children,
  description,
  disabled,
  errorText,
  fullWidth,
  inputId,
  label,
  multiple,
  name,
  onChange,
  onCloseAlert,
  primary,
  transparent,
  small,
  hideLabel,
  icon,
  buttonProps,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files as FileList;
    const fileNames = [...fileList].map(file => file);
    onChange?.(event, fileNames);
  }

  return (
    <FileUploadContext.Provider value={{
      description,
      disabled,
      errorText,
      fullWidth,
      inputId,
      inputRef,
      onCloseAlert,
    }}
    >
      <Input
        {...rest}
        {...(accept && { accept: accept.toString() })}
        aria-hidden="true"
        id={inputId}
        name={name}
        tabIndex={-1}
        type="file"
        onChange={handleOnChange}
        ref={inputRef}
        multiple={multiple}
      />
      <UploadButton
        {...buttonProps}
        label={label}
        type="button"
        primary={primary}
        transparent={transparent}
        hideLabel={hideLabel}
        icon={icon}
        small={small}
      />
    </FileUploadContext.Provider>
  );
};

FileUploadButton.propTypes = {
  /**
   * Specifies allowed file types
   */
  accept: PT.arrayOf(PT.string.isRequired),
  /**
   * Rendered list of uploader descriptions
   */
  description: PT.node,
  /**
   * Specifies if the control is disabled
   */
  disabled: PT.bool,
  /**
   * Text displayed in Alert component when an error occurs
   */
  errorText: PT.string,
  /**
   * Specifies if the control takes full width of parent element
   */
  fullWidth: PT.bool,
  /**
   * ID for file input
   */
  inputId: PT.string.isRequired,
  /**
   * Text displayed as Button component label
   */
  label: PT.string.isRequired,
  /**
   * Specifies that the control will accept one or more values
   */
  multiple: PT.bool,
  /**
   * Name of the form control
   */
  name: PT.string,
  /**
   * Callback function called when file(s) are selected from file system
   */
  onChange: PT.func,
  /**
   * Callback function called when Alert close button is selected
   */
  onCloseAlert: PT.func,
  /**
   * Render small variant of Button
   */
  small: PT.bool,
  /**
   * Render primary variant of Button
   */
  primary: PT.bool,
  /**
   * Render transparent variant of Button
   */
  transparent: PT.bool,
};

FileUploadButton.defaultProps = {
  accept: undefined,
  description: undefined,
  disabled: false,
  errorText: '',
  fullWidth: false,
  multiple: false,
  name: '',
  onChange: noop,
  onCloseAlert: noop,
  small: false,
  primary: false,
  transparent: false,
};

export default FileUploadButton;
