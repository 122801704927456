import React, {
  FC,
  ReactElement,
  ReactNode,
  RefObject,
  useCallback,
} from 'react';
import PT, { Validator } from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { noop, MergeElementProps } from '@amzn/storm-ui-utils-v3';
import { Placement } from '@popperjs/core';
import { Theme } from '../theme';
import useInlinePopper from '../Popper/useInlinePopper';
import { PopperTrigger } from '../Popper/Popper.styles';
import PopperContainer from '../Popper/PopperContainer';
import AnchoredAlertContent from './AnchoredAlertContent';
import { SurfaceType } from '../Surface/types';

function modifyPopoverZIndex(theme: Theme) {
  return {
    ...theme,
    popover: {
      ...theme.popover,
      zIndex: theme.anchoredAlert.zIndex,
    },
  };
}

const NodeSafeHTMLElement = (typeof HTMLElement !== 'undefined' ? HTMLElement : Object);

export interface AnchoredAlertProps extends MergeElementProps<'div'> {
    /**
   * The unique id attribute that is supplied to the <Popper> element. Useful
   * for identifying the `<AnchoredAlert>` in front-end telemetry.
   * @defaultValue `undefined`
   */
  id?: string;
    /**
   * Specifies whether the `<AnchoredAlert>` is visible.
   * @defaultValue `false`
   */
  isOpen?: boolean;
    /**
   * Justifies the <AnchoredAlert> relative to the trigger.
   * @defaultValue `"center"`
   */
  align?: 'start' | 'center' | 'end' | string;
    /**
   * Content of the AnchoredAlert.
   * @defaultValue `undefined`
   */
  children?: ReactNode;
    /**
   * Screen reader label for the `<AnchoredAlert>` close button. Required when
   * rendering the close button for the `<AnchoredAlert>` to be accessible.
   * @defaultValue `"Dismiss Alert"`
   */
  closeButtonLabel?: string;
    /**
   * Content of the AnchoredAlert.
   * @defaultValue `""`
   */
  message?: string | ReactNode;
    /**
   * Callback that is called when the specified trigger element is clicked.
   * @defaultValue `undefined`
   */
  onClick?: (event: React.MouseEvent<HTMLSpanElement>) => void;
    /**
   * Callback that is called when the close button on the <Popper> element is clicked.
   * @defaultValue `() => undefined`
   */
  onCloseButtonClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    /**
   * Positions the `<AnchoredAlert>` element relative to the trigger.
   * @defaultValue `"top"`
   */
  position?: 'top' | 'right' | 'bottom' | 'left' | string;
    /**
   * Fine grain control over the space between the PIP and the anchor.
   * @defaultValue `0`
   */
  spacing?: number;
    /**
   * This element will render the <AnchoredAlert> when clicked. The <AnchoredAlert> will be
   * positioned and aligned relative to this element.
   */
  trigger: ReactElement;
    /**
   * Specifies the stylistic theme for the `<AnchoredAlert>`.
   * @defaultValue `"light"`
   */
  type?: 'light' | 'dark' | 'blue' | string;
    /**
   * Callback that is called when the close button on the `<AnchoredAlert>` element is clicked.
   * @defaultValue `true`
   */
  withCloseButton?: boolean;
    /**
   * Content rendered after the main content or message
   * @defaultValue `undefined`
   */
  footer?: ReactNode;
    /**
   * Alternate location for the close button
   * @defaultValue `"default"`
   */
  closeIconPosition?: 'default' | 'topCorner';
    /**
   * A React Ref that contains the HTMLElement this popper will be rendered into.
   * @defaultValue `undefined`
   */
  portalRef?: RefObject<HTMLElement>
    /**
  * A React Ref to a element that popper will attempt to stay inside by flipping
  * @defaultValue `undefined`
  */
  boundaryRef?: RefObject<HTMLElement>;
    /**
   * Sets whether the body of the underlying Popper component is focusable when navigating with a keyboard.
   * @defaultValue `false`
   */
  focusableBody?: boolean;
}

const AnchoredAlert: FC<React.PropsWithChildren<AnchoredAlertProps>> = props => {
  const {
    align = 'center',
    children,
    closeButtonLabel = 'Dismiss Alert',
    closeIconPosition = 'default',
    footer,
    isOpen = false,
    message = '',
    onClick,
    onCloseButtonClick,
    position = 'top',
    spacing = 0,
    trigger,
    type = 'light',
    withCloseButton = true,
    portalRef,
    boundaryRef,
    ...rest
  } = props;

  const placement = align === 'center' ? position : `${position}-${align}`;

  const surfaceType: SurfaceType = type === 'light' || type === 'dark' || type === 'blue' ? type : 'light';

  const {
    attributes,
    styles,
    setArrowElement,
    setPopperElement,
    setTriggerElement,
    update,
  } = useInlinePopper({
    offsetDistance: spacing,
    placement: placement as Placement,
    enableFlip: (!!boundaryRef),
    preventOverflow: {
      mainAxis: (!!boundaryRef),
    },
    withArrow: true,
    boundaryRef,
  });

  /**
   * Handles mouse clicks on the <AnchoredAlert /> close button.
   */
  const handleCloseClicked = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    // call the callback function passed via props.
    onCloseButtonClick?.(event);
  }, [onCloseButtonClick]);

  /**
   * Catches the click event from the <AnchoredAlert /> trigger and only
   * call the 'onClick' callback if it was passed.
   */
  const preventTriggerClickThrough = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (onClick) {
      onClick(event);
    }
  }, [onClick]);

  const handleAnchoredAlertEntering = useCallback((node: unknown, isAppearing: boolean) => {
    if (!isAppearing && update !== null) {
      update();
    }
  }, [update]);

  return (
    <>
      <PopperTrigger
        onClick={preventTriggerClickThrough}
        ref={setTriggerElement}
      >
        {trigger}
      </PopperTrigger>
      <ThemeProvider theme={modifyPopoverZIndex}>
        <PopperContainer
          attributes={attributes}
          closeButtonLabel={closeButtonLabel}
          closeIconPosition={closeIconPosition}
          disablePortal={!portalRef}
          footer={footer}
          handleCloseClicked={handleCloseClicked}
          handlePopoverEntering={handleAnchoredAlertEntering}
          isOpen={isOpen}
          message={message}
          setArrowElement={setArrowElement}
          setPopperElement={setPopperElement}
          styles={styles}
          type={surfaceType}
          withCloseButton={withCloseButton}
          withArrow
          portalRef={portalRef}
          {...rest}
        >
          <AnchoredAlertContent
            closeButtonLabel={closeButtonLabel}
            closeIconPosition={closeIconPosition}
            footer={footer}
            handleCloseClicked={handleCloseClicked}
            message={message}
            type={surfaceType}
            withCloseButton={withCloseButton}
          >
            {children}
          </AnchoredAlertContent>
        </PopperContainer>
      </ThemeProvider>
    </>
  );
};

AnchoredAlert.propTypes = {
  /**
   * This element will render the <AnchoredAlert> when clicked. The <AnchoredAlert> will be
   * positioned and aligned relative to this element.
   */
  trigger: PT.element.isRequired,
  /**
   * Justifies the <AnchoredAlert> relative to the trigger.
   */
  align: PT.oneOf(['start', 'center', 'end']),
  /**
   * Content of the AnchoredAlert.
   */
  children: PT.node,
  /**
   * The unique id attribute that is supplied to the <Popper> element. Useful
   * for identifying the `<AnchoredAlert>` in front-end telemetry.
   */
  id: PT.string,
  /**
   * Specifies whether the `<AnchoredAlert>` is visible.
   */
  isOpen: PT.bool,
  /**
   * Content of the AnchoredAlert.
   */
  message: PT.oneOfType([
    PT.string,
    PT.node,
  ]),
  /**
   * Callback that is called when the specified trigger element is clicked.
   */
  onClick: PT.func,
  /**
   * Callback that is called when the close button on the <Popper> element is clicked.
   */
  onCloseButtonClick: PT.func,
  /**
   * Positions the `<AnchoredAlert>` element relative to the trigger.
   */
  position: PT.oneOf(['top', 'right', 'bottom', 'left']),
  /**
   * Fine grain control over the space between the PIP and the anchor.
   */
  spacing: PT.number,
  /**
   * Specifies the stylistic theme for the `<AnchoredAlert>`.
   */
  type: PT.oneOf(['light', 'dark', 'blue']),
  /**
   * Callback that is called when the close button on the `<AnchoredAlert>` element is clicked.
   */
  withCloseButton: PT.bool,
  /**
   * Screen reader label for the `<AnchoredAlert>` close button. Required when
   * rendering the close button for the `<AnchoredAlert>` to be accessible.
   */
  closeButtonLabel: PT.string,
  /**
   * Content rendered after the main content or message
   */
  footer: PT.node,
  /**
   * Alternate location for the close button
   */
  closeIconPosition: PT.oneOf(['default', 'topCorner']),
  /**
   * A React Ref that contains the HTMLElement this popper will be rendered into.
   */
  portalRef: PT.shape(
    { current: (PT.instanceOf(NodeSafeHTMLElement)) },
  ) as Validator<RefObject<HTMLElement>>,
  /**
  * A React Ref to a element that popper will attempt to stay inside by flipping
  */
  boundaryRef: PT.shape(
    { current: (PT.instanceOf(NodeSafeHTMLElement)) },
  ) as Validator<RefObject<HTMLElement>>,
  /**
   * Sets whether the body of the underlying Popper component is focusable when navigating with a keyboard.
   */
  focusableBody: PT.bool,
};

AnchoredAlert.defaultProps = {
  align: 'center',
  children: undefined,
  closeButtonLabel: 'Dismiss Alert',
  closeIconPosition: 'default',
  footer: undefined,
  isOpen: false,
  id: undefined,
  message: '',
  onClick: undefined,
  onCloseButtonClick: noop,
  position: 'top',
  spacing: 0,
  type: 'light',
  withCloseButton: true,
  portalRef: undefined,
  boundaryRef: undefined,
  focusableBody: false,
};

export default AnchoredAlert;
