import React, {
  ReactNode,
  KeyboardEvent,
  MouseEvent as ReactMouseEvent,
} from 'react';
import styled, { css } from 'styled-components';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import { keyboardKeynames as keys } from '@amzn/storm-ui-utils';
import { Link } from '../../Text';
import { TextButton } from '../../Button';
import isMobile from '../../theme/style-mixins/isMobile/isMobile';
import { ThemeType } from '../../theme/ThemeProvider';
import { ButtonProps } from '../../Button/Button';
import { useTablistContext } from '../TablistContext';
import type { DataTaktAttributes } from '../../TaktIdContext';

interface ButtonTabProps {
  $label: string;
  $active?: boolean;
}

export type TabSize = 'base' | 'small';

export interface TextTabProps {
  size?: TabSize;
}

export const tabMarginsBase = ({ navigation }: NavigationThemeType) => (`
  margin-inline-end: ${navigation.tabs.marginEndBase};
  :last-of-type {
    margin-inline-end: 0;
  }
`);

type NavigationThemeType = { navigation: ThemeType['navigation']}

export const tabMarginsSmall = ({ navigation }: NavigationThemeType) => (`
  margin-inline-end: ${navigation.tabs.marginEndSmall};
  :last-of-type {
    margin-inline-end: 0;
  }
`);

const tabStylesBase = ({ navigation }: NavigationThemeType) => (`
  font-size: ${navigation.tabs.base};
  line-height: 22px;
  padding: ${navigation.tabs.paddingAllBase};
`);

const focusedTabStyle = css`
  :focus {
    outline: 2px solid ${({ theme }) => theme.palette.auiblue};
    outline-offset: -2px;
    box-shadow: none;
  }
`;

const activeTabStyle = ({ navigation }: NavigationThemeType) => (`
  border-bottom: ${navigation.tabs.activeBottomBorder};
  font-weight: ${navigation.tabs.activeFontWeight};
  color: ${navigation.tabs.activeColor};
  outline: none;
`);

const defaultTabStyle = ({ navigation }: NavigationThemeType) => (`
  border-bottom: ${navigation.tabs.bottomBorder};
  font-weight: ${navigation.tabs.fontWeight};
`);

const tabStyle = (label: string, { navigation }: NavigationThemeType) => (`
  display: inline-flex;
  align-items: center;
  background: none;
  border: none;
  margin: 0;
  cursor: pointer;
  height: auto;
  color: ${navigation.tabs.color};

  flex-direction: column;
  justify-content: space-between;

  :hover {
    border-bottom: ${navigation.tabs.activeBottomBorder};
    color: ${navigation.tabs.color};
    text-decoration: none;
  }

  :active, :visited {
    color: ${navigation.tabs.color};
  }

  :link {
    color: ${navigation.tabs.color};
    text-decoration: none;
  }

  ::after {
    content: "${label}";
    content: "${label}" / "";
    height: 0;
    visibility: hidden;
    overflow: hidden;
    user-select: none;
    pointer-events: none;
    font-weight: bold;

    @media speech {
      display: none;
    }
  }
`);

const tabStylesSmall = ({ navigation }: NavigationThemeType) => (`
  font-size: ${navigation.tabs.small};
  line-height: 1;
  padding: ${navigation.tabs.paddingAllSmall};
`);

const ButtonTab = styled(TextButton).attrs(({ onFocus, onBlur }) => {
  const { isFocusVisible, focusProps } = useFocusRing();
  return ({
    /*
     *`useFocusRing()` uses `onFocus` and `onBlur` props, so `mergeProps()` must be used to
     * make sure user supplied `onFocus` and `onBlur` are also called.
    */
    ...mergeProps({ onFocus, onBlur }, focusProps),
    focusVisible: isFocusVisible,
  });
})<ButtonTabProps>`
  ${({ $label, theme }) => tabStyle($label, theme)};
  ${({ theme, $active }) => ($active ? activeTabStyle(theme) : defaultTabStyle(theme))};
  ${({ theme, size }) => {
    switch (size) {
      case 'small': return tabStylesSmall(theme);
      default: return tabStylesBase(theme);
    }
  }}
  ${({ focusVisible }) => (focusVisible && isMobile(focusedTabStyle))}
`;

const TextTab = styled.span<TextTabProps>`
  ${({ theme, size }) => {
    switch (size) {
      case 'small': return tabMarginsSmall(theme);
      default: return tabMarginsBase(theme);
    }
  }};
  ${({ theme, size }) => {
    switch (size) {
      case 'small': return tabStylesSmall(theme);
      default: return tabStylesBase(theme);
    }
  }};
  color: ${({ theme }) => theme.navigation.tabs.color};

  ${isMobile(css`
    white-space: nowrap;
  `)}
`;

const handleKeyDown = (event: KeyboardEvent): void => {
  // By default, buttons handle the Enter key on KeyDown. We prevent this because we want
  // to listen to KeyUp to prevent repeat triggers.
  if (event.key === keys.ENTER) {
    event.preventDefault();
  }
}

const handleKeyUp = (event: KeyboardEvent<HTMLButtonElement>): void => {
  const { key } = event;
  //  Dispatch a fake mouse click to the Tab when a ENTER or SPACE key are released
  if (key === keys.ENTER || key === keys.SPACE) {
    const { currentTarget } = event;
    const mouseEvent = new MouseEvent('click', {
      view: currentTarget.ownerDocument.defaultView,
      bubbles: true,
      cancelable: true,
    });
    currentTarget.dispatchEvent(mouseEvent);
    event.preventDefault();
  }
}

interface TabProps extends Omit<ButtonProps, '$label'> {
  active?: boolean;
  dataTaktAttributes: DataTaktAttributes;
  href?: string;
  label: ReactNode;
  onClick?: (event: ReactMouseEvent, ...args: any[]) => void;
  size?: TabSize;
}

const Tab = ({
  active,
  dataTaktAttributes,
  href,
  label,
  onClick,
  size,
  ...rest
}: TabProps) => {
  const { isTablist } = useTablistContext();

  if (href) {
    return (
      <span
        {...(isTablist ? { role: 'tab' } : {})}
        aria-selected={active}
      >
        <ButtonTab
          {...dataTaktAttributes}
          {...rest}
          as={Link}
          $label={typeof label === 'string' ? label : ''}
          size={size}
          $active={active}
          onClick={onClick}
          href={href}
          role="link"
          {...(isTablist ? { tabIndex: active ? 0 : -1 } : {})}
        >
          {label}
        </ButtonTab>
      </span>
    );
  }

  if (onClick) {
    return (
      <ButtonTab
        {...dataTaktAttributes}
        {...rest}
        $label={typeof label === 'string' ? label : ''}
        size={size}
        $active={active}
        aria-selected={active}
        onClick={onClick}
        {...(isTablist ? {
          tabIndex: active ? 0 : -1,
          role: 'tab',
          onKeyDown: handleKeyDown,
          onKeyUp: handleKeyUp,
        } : {})}
      >
        {label}
      </ButtonTab>
    );
  }
  return (
    <TextTab
      {...rest}
      size={size}
      {...(isTablist ? {
        tabIndex: -1,
        role: 'tab',
      } : {})}
    >
      {label}
    </TextTab>
  );
}

export default Tab;
