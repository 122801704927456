// import React, { memo, forwardRef, FC } from 'react';
import React, { memo, Ref, FC } from 'react';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import PT, { Validator } from 'prop-types';
import styled, { css } from 'styled-components';
import { externalLinkAlt } from '@amzn/storm-ui-icons';
import { MergeStyledComponentElementProps } from '@amzn/storm-ui-utils';
import Icon from '../../Icon';
import focusOutline from '../../FocusIndicator/styleMixins/focusOutline';
import isMobile from '../../theme/style-mixins/isMobile/isMobile';
import { TaktProps } from '../../types/TaktProps';
import { TaktIdConsumer, createStormTaktId } from '../../TaktIdContext';

export type LinkSize = 'inherit' | 'base' | 'small' | 'tiny'
export type LinkTheme = {
  link: {
    overrideAuiLinkStyle?: boolean
  }
  typography: {
    a?: string;
  }
}

const NodeSafeHTMLElement = (typeof HTMLElement !== 'undefined' ? HTMLElement : Object);

/*
* When using alternative background colors, links must have additional selector specificity
* to override AUI rules. Existing applications depend on legacy link specificity, so it
* must only be applied to links within <SurfaceXxxx> containers
*/
function overrideAuiLinkStyle({ theme }: { theme: LinkTheme }) {
  if (theme?.link?.overrideAuiLinkStyle === true) {
    return `a:is(&&), a:is(&&):visited, a:is(&&):active, a:is(&&):link {${theme.typography.a}}`;
  }
  return '';
}

export interface StyledLinkProps {
  $size: LinkSize;
}

const StyledLink = styled('a')
  .attrs(({ onFocus, onBlur }) => {
    const { isFocusVisible, focusProps } = useFocusRing();
    return ({
      ...mergeProps({ onFocus, onBlur }, focusProps),
      $focusVisible: isFocusVisible,
    });
  }) <StyledLinkProps>`
  ${({ theme }) => (theme.typography.a)};
  font-size: ${({ $size, theme }) => (theme.link[$size] || 'inherit')};

  ${isMobile(css<StyledLinkProps>`
    font-size: ${({ $size, theme }) => (theme.mobile.link[$size] || 'inherit')};
  `)}

  :focus {
    ${({ $focusVisible }) => ($focusVisible && focusOutline)}
  }
  ${overrideAuiLinkStyle}
`;
StyledLink.displayName = 'StyledLink';

const StyledIcon = styled(Icon)`
  font-size: 0.85em;
`;

export interface LinkProps extends TaktProps, MergeStyledComponentElementProps<'a'> {
  /**
    *  Use to specify the user is leaving the current website when clicking this link.
    * @defaultValue `false`
    */
  external?: boolean;
  /**
    * The size of the link element
    * @defaultValue `"inherit"`
    */
  size?: LinkSize;
  /**
    * A React Ref that contains the HTMLAnchorElement this link will render.
    * @defaultValue `undefined`
    */
  anchorRef?: Ref<HTMLAnchorElement>;
}

/**
 * Link
 */
const Link: FC<React.PropsWithChildren<LinkProps>> = ({
  children,
  href,
  external = false,
  size = 'inherit',
  anchorRef,
  taktId,
  taktValue,
  ...rest
}) => (
  <TaktIdConsumer taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('link')}>
    {({ getDataTaktAttributes }) => (
      <StyledLink
        {...getDataTaktAttributes()}
        {...rest}
        $size={size}
        href={href}
        ref={anchorRef}
      >
        {children}
        {external && (
          <>
            &nbsp;
            <StyledIcon type={externalLinkAlt} />
          </>
        )}
      </StyledLink>
    )}
  </TaktIdConsumer>
);

Link.propTypes = {
  /**
    * The text of the link. This can also be a react node when special formatting is required.
    */
  children: PT.node.isRequired,
  /**
    * The Link URL. If you don't have an href, you should probably be using a TextButton instead
    */
  href: PT.string.isRequired,
  /**
    *  Use to specify the user is leaving the current website when clicking this link.
    */
  external: PT.bool,
  /**
    * The size of the link element
    */
  size: PT.oneOf(['inherit', 'base', 'small', 'tiny']),
  /**
    * A React Ref that contains the HTMLAnchorElement this link will render.
    */
  anchorRef: PT.shape(
    { current: (PT.instanceOf(NodeSafeHTMLElement)) },
  ) as Validator<Ref<HTMLAnchorElement>>,
}
Link.defaultProps = {
  external: false,
  size: 'inherit',
  anchorRef: undefined,
};
Link.displayName = 'Link';

export default memo(Link);
