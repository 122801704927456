import React, {
  createRef,
  FC,
  KeyboardEvent,
  MouseEvent,
  PureComponent,
  ReactNode,
  ContextType,
} from 'react';
import PT from 'prop-types';
import { ThemeContext } from 'styled-components';
import {
  getFocusableElements,
  keyboardKeynames as keys,
  MergeElementProps,
  scrollIntoBounds,
} from '@amzn/storm-ui-utils-v3';
import { CardPadding } from '../Card/types';
import {
  CardStyled,
  CardBodyStyled,
} from '../Card/Card.styles';
import {
  DefaultText,
  Tab,
  TabGroup,
  TabGroupWrapper,
  TabRemainSpace,
  TabWrapper,
  Wrapper,
} from './TabbedCard.styles';
import { defaultTheme } from '../theme';
import { TaktIdProvider, TaktIdConsumer, createStormTaktId } from '../TaktIdContext';
import type { TaktProps } from '../types/TaktProps';

interface isActivatable {
  isActive: boolean;
}

// Used to add default text wrapper
// or isActive prop to custom components
const modifyTabChildren = (tabChildren: ReactNode, isActive = false) => {
  if (typeof tabChildren === 'string') {
    return (
      <DefaultText isActive={isActive} $label={tabChildren}>{tabChildren}</DefaultText>
    );
  }

  if (React.isValidElement(tabChildren)) {
    const { type } = tabChildren;

    // WARNING...SMELLY CODE AHEAD
    if (
      // is a function component
      /createElement/.test(String(type))

      // is a class component
      || (typeof type === 'function' && /react.element/.test(String((tabChildren as unknown as { $$typeof: symbol }).$$typeof)))
    ) {
      return React.cloneElement(tabChildren, { isActive } as isActivatable);
    }
  }

  // don't modify if custom element is a standard dom element
  return tabChildren;
};

export interface TabbedCardTabItemProps extends TaktProps, Omit<MergeElementProps<'button'>, 'onChange' | 'ref'> {
  onChange: (value: string, event: KeyboardEvent | MouseEvent) => void;
  isActive?: boolean;
  tabChildren: ReactNode;
  value: string;
}

// React component representing the physical tab and its logic
const TabbedCardTabItem: FC<React.PropsWithChildren<TabbedCardTabItemProps>> = props => {
  const {
    onChange,
    isActive,
    tabChildren,
    value,
    taktId,
    taktValue,
    ...itemProps
  } = props;

  const handleKeyUp = (event: KeyboardEvent): void => {
    const { key } = event;
    if (key === keys.ENTER || key === keys.SPACE) {
      onChange(value, event);
      event.preventDefault();
    }
  };

  const handleChange = (event: MouseEvent): void => {
    onChange(value, event);
  };

  return (
    <TaktIdConsumer taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('tabbed-card-tab-item')}>
      {({ getDataTaktAttributes }) => (
        <Tab
          {...getDataTaktAttributes()}
          type="button"
          key={value}
          id={value}
          isActive={isActive}
          aria-selected={isActive}
          role="tab"
          tabIndex={isActive ? 0 : -1}
          onClick={handleChange}
          onKeyUp={handleKeyUp}
          {...itemProps}
        >
          <TabWrapper $isActive={isActive}>
            {modifyTabChildren(tabChildren, isActive)}
          </TabWrapper>
        </Tab>
      )}
    </TaktIdConsumer>
  );
};

TabbedCardTabItem.propTypes = {
  /**
   * Denotes whether the tab is in an active state (selected) or not.
   */
  isActive: PT.bool,
  /**
   * This function returns the value of the tab when changed event is fired.
   */
  onChange: PT.func.isRequired,
  /**
   * The contents of the tab relating to this card. Any content may be passed
   * for custom formatting. We will pass isActive to any custom class or function
   * component, for use in special actions within those tabs based on state.
   * A string will give default styles.
   */
  tabChildren: PT.node.isRequired,
  /**
   * Must be a unique value for this tabbed card group. Used by parent to determine
   * which card is selected.
   */
  value: PT.string.isRequired,
};

TabbedCardTabItem.defaultProps = {
  isActive: false,
};

export interface TabbedCardProps extends TaktProps, Omit<MergeElementProps<'div'>, 'onChange' | 'ref'> {
  /**
     * Causes the tabs in the header to not render when only a single tab is present.
     * @defaultValue `false`
     */
  collapseHeaderWithOneTab?: boolean;
  /**
     * Optional nav content that will display to the right of the tabs.
     * @defaultValue `null`
     */
  navChildren?: ReactNode;
  /**
     * The value of the selected tab (must match one of the TabbedCardItem values).
     */
  selectedTab: string;
  /**
     * This function returns the value of the tab when changed event is fired.
     */
  onChange: (value: string, event: MouseEvent | KeyboardEvent) => void;
  /**
     * Use to easily override the default theme padding for this card.
     * @defaultValue `null`
     */
  padding?: CardPadding;
}

interface TabbedCardState {
  isTabOverflow: boolean;
}

// React component representing the physical tabbed card and its logic
class TabbedCard extends PureComponent<TabbedCardProps, TabbedCardState> {
  static contextType = ThemeContext;

  context: ContextType<typeof ThemeContext> = defaultTheme;

  private navRef = createRef<HTMLDivElement>();

  private tabGroupRef = createRef<HTMLDivElement>();

  static propTypes = {
    /*
     * The contents of the tabbed card. Must use the `<TabbedCardItem />` component.
     */
    children: PT.node.isRequired,
    /**
     * Causes the tabs in the header to not render when only a single tab is present.
     */
    collapseHeaderWithOneTab: PT.bool,
    /**
     * Optional nav content that will display to the right of the tabs.
     */
    navChildren: PT.node,
    /**
     * The value of the selected tab (must match one of the TabbedCardItem values).
     */
    selectedTab: PT.string.isRequired,
    /**
     * This function returns the value of the tab when changed event is fired.
     */
    onChange: PT.func.isRequired,
    /**
     * Use to easily override the default theme padding for this card.
     */
    padding: PT.oneOfType([
      PT.number,
      PT.oneOf([
        'none',
        'micro',
        'mini',
        'small',
        'base',
        'medium',
        'large',
        'xlarge',
        'xxlarge',
      ]),
    ]),
  }

  static defaultProps = {
    collapseHeaderWithOneTab: false,
    navChildren: null,
    padding: null,
  }

  constructor(props: TabbedCardProps) {
    super(props);
    this.state = { isTabOverflow: false };
  }

  componentDidMount(): void {
    const tabsWidth = this.tabGroupRef.current?.scrollWidth;
    const tabsContainerWidth = this.tabGroupRef.current?.offsetWidth;

    /* Set a isTabOverflow flag on whether to add the scroll indicator */
    if (tabsWidth && tabsContainerWidth && (tabsWidth > tabsContainerWidth)) {
      this.setState({ isTabOverflow: true });
    }
    /* Scroll the active tab to be visible in the frame if required. */
    scrollIntoBounds(
      this.navRef.current,
      this.navRef.current?.querySelector('[aria-selected=true][role="tab"]'),
    );
  }

  // KeyDown events to handle for keyboard accessibility. Best practices for Tabs with manual
  // activation here: https://www.w3.org/TR/wai-aria-practices/examples/tabs/tabs-2/tabs.html
  handleKeyDown = (event: KeyboardEvent): void => {
    const { key } = event;

    // By default, buttons handle the Enter key on KeyDown. We prevent this because we want
    // to listen to KeyUp to prevent repeat triggers.
    if (key === keys.ENTER) {
      event.preventDefault();
    }

    if (this.tabGroupRef.current) {
      const focusableEls = getFocusableElements(this.tabGroupRef.current);
      const firstFocusableEl = focusableEls[0];
      const lastFocusableEl = focusableEls[focusableEls.length - 1];

      // When "Left Arrow" or "Right Arrow" is pressed, move focus to the left or right tab
      if ((key === keys.ARROW_RIGHT
        || key === keys.ARROW_LEFT
        || key === keys.HOME
        || key === keys.END)
        && (document.activeElement && document.activeElement instanceof HTMLElement)
      ) {
        const currentIndex = focusableEls.indexOf(document.activeElement);
        let newFocusIndex = currentIndex;

        // When "Right Arrow" is pressed, focus on the tab to the right
        if (key === keys.ARROW_RIGHT) {
          newFocusIndex = currentIndex + 1;
          if (document.activeElement === lastFocusableEl) {
            newFocusIndex = 0;
          }
        }

        // When "Left Arrow" is pressed, focus on the tab to the left
        if (key === keys.ARROW_LEFT) {
          newFocusIndex = currentIndex - 1;
          if (document.activeElement === firstFocusableEl) {
            newFocusIndex = focusableEls.length - 1;
          }
        }

        // When "End" is pressed, focus on the last tab in the tab group
        if (key === keys.END) {
          newFocusIndex = focusableEls.length - 1;
        }

        // When "Home" is pressed, focus on the first tab in the tab group
        if (key === keys.HOME) {
          newFocusIndex = 0;
        }

        focusableEls[newFocusIndex].focus();
        event.preventDefault();
      }
    }
  }

  render(): JSX.Element {
    const {
      collapseHeaderWithOneTab,
      selectedTab,
      navChildren,
      onChange,
      children,
      padding,
      taktId,
      taktValue,
      ...rest
    } = this.props;

    const theme = this.context;

    const { isTabOverflow } = this.state;

    const paddingValue = padding || theme.card.tabs.paddingValue;

    let tabContent;
    const tabs = React.Children.map(children, child => {
      if (!React.isValidElement(child)) {
        return null;
      }

      const {
        value,
        tabChildren,
        children: itemChildren,
        ...itemRest
      } = child.props;

      const isActive = selectedTab === value;

      if (isActive) {
        tabContent = child;
      }

      return (
        <TabbedCardTabItem
          onChange={onChange}
          isActive={isActive}
          tabChildren={tabChildren}
          value={value}
          {...itemRest}
        />
      );
    });

    return (
      <TaktIdProvider taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('tabbed-card')}>
        <CardStyled {...rest}>
          <Wrapper isTabOverflow={isTabOverflow}>
            <TabGroupWrapper ref={this.navRef}>
              {!(collapseHeaderWithOneTab && (tabs && tabs.length < 2)) && (
                <TabGroup
                  onKeyDown={event => this.handleKeyDown(event)}
                  ref={this.tabGroupRef}
                  role="tablist"
                >
                  {tabs}
                  {navChildren}
                  <TabRemainSpace />
                </TabGroup>
              )}
            </TabGroupWrapper>
          </Wrapper>
          <CardBodyStyled padding={paddingValue}>
            {tabContent}
          </CardBodyStyled>
        </CardStyled>
      </TaktIdProvider>
    );
  }
}
export default TabbedCard;
