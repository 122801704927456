import React, {
  ComponentPropsWithoutRef,
  FC,
  RefObject,
  ReactNode,
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useRef,
} from 'react';
import PropTypes, { Validator } from 'prop-types';
import { Transition } from 'react-transition-group';
import { MergeElementProps, noop } from '@amzn/storm-ui-utils';
import { PaddingOptions } from '../types/PaddingOptions';
import Portal from '../Portal';
import useModalBehaviors from '../Modal/useModalBehaviors';
import { TaktIdProvider, createStormTaktId } from '../TaktIdContext';
import type { TaktProps } from '../types/TaktProps';
import Overlay from './Overlay';

export interface SecondaryViewProps extends TaktProps, MergeElementProps<'div'> {
  /**
   * Accepts elements to be rendered inside the close button in the header.
   * @defaultValue `"Done"`
   */
  closeButtonLabel?: ReactNode;
  /**
   * Props that are applied to the CloseButton component.
   * @defaultValue `undefined`
   */
  closeButtonProps?: ComponentPropsWithoutRef<'button'>;
  /**
   * Control open / close of secondary view. It will animate automatically on change.
   * @defaultValue `false`
   */
  isOpen?: boolean;
  /**
   * Fired when any close action occurs such as; hitting the close button, or hitting escape.
   * @defaultValue `() => undefined`
   */
  onClose?: (event?: MouseEvent | KeyboardEvent) => void;
  /**
   * Sets the padding around the secondary view's content.
   * @defaultValue `"base"`
   */
  padding?: PaddingOptions;
  /**
   * This should correspond to the ROOT level dom node id that the modal will render into.
   * When this prop is not explicitly passed a value, the secondary view will mount into the
   * div with id matching the portalElementId property in the popover object of the theme.
   * @defaultValue `undefined`
   */
  secondaryViewElementId?: string;
  /**
   * Pass a ref to an element here to use as the toggle element. Focus will move back to this
   * element when the bottom sheet closes.
   * @defaultValue `undefined`
   */
  toggleEl?: RefObject<HTMLElement | undefined>;
}

const SecondaryView: FC<React.PropsWithChildren<SecondaryViewProps>> = props => {
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const fadeDuration = 150;

  const {
    children,
    closeButtonLabel,
    closeButtonProps,
    isOpen,
    onClose,
    padding = 'base',
    secondaryViewElementId,
    toggleEl,
    taktId,
    taktValue,
    ...rest
  } = props;

  const {
    handleKeyUp,
    handleKeyDown,
    handleFocusOnExit,
    handleFocusOnEnter,
  } = useModalBehaviors({
    initialRef: containerRef,
    onClose,
    triggerRef: toggleEl,
    firstRef: closeButtonRef,
  });

  const teardown = useCallback(() => {
    handleFocusOnExit();
  }, [handleFocusOnExit]);

  return (
    <Transition
      timeout={fadeDuration}
      appear
      in={isOpen}
      onExit={teardown}
      onEntered={handleFocusOnEnter}
      nodeRef={containerRef}
    >
      {transitionState => {
        if (transitionState === 'exited') return null;
        return (
          <Portal containerId={secondaryViewElementId}>
            <TaktIdProvider taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('secondary-view')}>
              <Overlay
                {...rest}
                closeButtonProps={closeButtonProps}
                fadeDuration={fadeDuration}
                transitionState={transitionState}
                handleKeyDown={handleKeyDown}
                handleKeyUp={handleKeyUp}
                containerRef={containerRef}
                onClose={onClose}
                closeButtonRef={closeButtonRef}
                closeButtonLabel={closeButtonLabel}
                padding={padding}
              >
                {children}
              </Overlay>
            </TaktIdProvider>
          </Portal>
        );
      }}
    </Transition>
  );
};

SecondaryView.propTypes = {
  /**
   * The React nodes/nodes that are rendered as content of the SecondaryView.
   */
  children: PropTypes.node.isRequired,
  /**
   * Accepts elements to be rendered inside the close button in the header.
   */
  closeButtonLabel: PropTypes.node,
  /**
   * Props that are applied to the CloseButton component.
   */
  closeButtonProps: PropTypes.objectOf(PropTypes.any),
  /**
   * Control open / close of secondary view. It will animate automatically on change.
   */
  isOpen: PropTypes.bool,
  /**
   * Fired when any close action occurs such as; hitting the close button, or hitting escape.
   */
  onClose: PropTypes.func,
  /**
   * Sets the padding around the secondary view's content.
   */
  padding: PropTypes.oneOf([
    'none',
    'micro',
    'mini',
    'small',
    'base',
    'medium',
    'large',
    'xlarge',
    'xxlarge',
  ]),
  /**
   * This should correspond to the ROOT level dom node id that the modal will render into.
   * When this prop is not explicitly passed a value, the secondary view will mount into the
   * div with id matching the portalElementId property in the popover object of the theme.
   */
  secondaryViewElementId: PropTypes.string,
  /**
   * Pass a ref to an element here to use as the toggle element. Focus will move back to this
   * element when the bottom sheet closes.
   */
  toggleEl: PropTypes.shape({
    current: PropTypes.instanceOf(typeof Element !== 'undefined' ? Element : Object),
  }) as Validator<RefObject<HTMLElement | undefined>>,
};

SecondaryView.defaultProps = {
  closeButtonLabel: 'Done',
  closeButtonProps: undefined,
  isOpen: false,
  onClose: noop,
  padding: 'base',
  secondaryViewElementId: undefined,
  toggleEl: undefined,
};

export default SecondaryView;
