import React, {
  useEffect,
  ReactNode,
  FC,
  RefObject,
  KeyboardEventHandler,
} from 'react';
import { angleInlineStart } from '@amzn/storm-ui-icons';
import { MergeElementProps, PreventScroll, isAndroidFirefox as getIsAndroidFirefox } from '@amzn/storm-ui-utils';
import {
  Content,
  CloseButton,
  CloseButtonIcon,
  Header,
  Shroud,
} from './SecondaryView.styles';
import View from './View';
import InlinePortalProvider from '../Portal/InlinePortalProvider';
import { TransitionStatus } from '../types/react-transition-group/Transition';
import { PaddingOptions } from '../types/PaddingOptions';
import { useTaktId, createStormTaktId } from '../TaktIdContext';

export function createSetSizeToVisualViewport(containerRef: React.RefObject<HTMLDivElement>) {
  const isAndroidFirefox = getIsAndroidFirefox();
  return function setSizeToVisualViewport(): void {
    if (containerRef.current instanceof HTMLDivElement && window.visualViewport) {
      // To fix issue caused by virtual keyboard
      if (isAndroidFirefox) {
        const { height, width } = window.visualViewport;
        const { style } = containerRef.current;
        style.height = `${height}px`;
        style.width = `${width}px`;
      }
    }
  };
}

export function fillEmptyWindowSpace(): () => void {
  const spaceFillElement = document.createElement('div');
  spaceFillElement.style.height = '100vh';
  spaceFillElement.style.width = '100vw';
  document.body.appendChild(spaceFillElement);
  return function cleanup() {
    if (spaceFillElement.parentElement) {
      spaceFillElement.parentElement.removeChild(spaceFillElement);
    }
  };
}

export interface OverlayProps extends MergeElementProps<'div'> {
  fadeDuration: number;
  transitionState: TransitionStatus;
  handleKeyDown: KeyboardEventHandler;
  handleKeyUp: KeyboardEventHandler;
  containerRef: RefObject<HTMLDivElement>;
  onClose?: () => void;
  closeButtonRef: RefObject<HTMLButtonElement>;
  closeButtonLabel?: ReactNode | string;
  closeButtonProps?: Record<string, unknown>;
  padding: PaddingOptions;
  children: ReactNode;
}

const Overlay: FC<React.PropsWithChildren<OverlayProps>> = ({
  fadeDuration,
  transitionState,
  handleKeyDown,
  handleKeyUp,
  containerRef,
  onClose,
  closeButtonRef,
  closeButtonLabel,
  closeButtonProps,
  padding,
  children,
  ...rest
}: OverlayProps) => {
  const { getDataTaktAttributes: getCloseButtonDataTaktAttributes } = useTaktId({ taktId: closeButtonProps?.taktId as (string | undefined), fallbackId: createStormTaktId('close-button') });
  useEffect(() => {
    if (typeof window !== 'undefined' && 'visualViewport' in window && window.visualViewport) {
      const { visualViewport, document } = window;

      const setSizeToVisualViewport = createSetSizeToVisualViewport(containerRef);
      visualViewport.addEventListener('resize', setSizeToVisualViewport);
      setSizeToVisualViewport();

      /*
      This is a fix for an iOS but where mobile window resizing leaves whitespace
      at the bottom of the screen after rotation. If the document height is not
      full height of the window, add an empty div to make up for the missing space
      */
      const { height: docHeight } = document.documentElement.getBoundingClientRect();
      const cleanupWindowFill = window.outerHeight > docHeight
        ? fillEmptyWindowSpace()
        : () => undefined;

      /* do any cleanup */
      return () => {
        visualViewport.removeEventListener('resize', setSizeToVisualViewport);
        cleanupWindowFill();
      };
    }
    return () => undefined;
  }, [containerRef]);

  return (
    <PreventScroll>
      <Shroud $transitionState={transitionState}>
        <View
          {...rest}
          role="presentation"
          $fadeDuration={fadeDuration}
          $fadeState={transitionState}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
          ref={containerRef}
        >
          <InlinePortalProvider>
            <Header>
              <CloseButton
                tabIndex={0}
                onClick={onClose}
                ref={closeButtonRef}
                type="button"
                {...getCloseButtonDataTaktAttributes({
                  taktValue: closeButtonProps?.taktValue as (Record<string, unknown> | undefined),
                })}
                {...closeButtonProps}
              >
                <CloseButtonIcon type={angleInlineStart} />
                {closeButtonLabel}
              </CloseButton>
            </Header>
            <Content padding={padding}>
              {children}
            </Content>
          </InlinePortalProvider>
        </View>
      </Shroud>
    </PreventScroll>
  );
};

Overlay.defaultProps = {
  closeButtonProps: undefined,
  onClose: undefined,
  closeButtonLabel: undefined,
};

export default Overlay;
