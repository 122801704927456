import React, {
  FC,
  ReactNode,
  MouseEventHandler,
  useRef,
} from 'react';
import PT from 'prop-types';
import { times } from '@amzn/storm-ui-icons-v3';
import {
  ChildrenWrapper,
  CloseButton,
  CloseIcon,
  FlexContainer,
  PopperContent,
  TooltipScrollContainer,
} from './PopperContentContainer.styles';
import PortalComponent from '../../Portal/PortalComponent';
import { PortalContextProvider } from '../../Portal/PortalContext';
import { createStormTaktId } from '../../TaktIdContext';

type ThemeType = 'light' | 'dark' | 'blue';

export interface PopperContentContainerProps {
  type?: ThemeType;
  withCloseButton?: boolean;
  closeButtonLabel?: string;
  handleCloseClicked: MouseEventHandler<HTMLButtonElement>;
  footer?: ReactNode;
  children?: ReactNode;
  padding?: 'none' | 'micro' | 'mini' | 'small' | 'base' | 'medium' | 'large' | 'xlarge' | 'xxlarge';
}

const PopperContentContainer: FC<React.PropsWithChildren<PopperContentContainerProps>> = ({
  children,
  closeButtonLabel,
  handleCloseClicked,
  footer,
  padding,
  type = 'light',
  withCloseButton,
}) => {
  const portalRef = useRef<HTMLDivElement>(null);
  return (
    <>
      <TooltipScrollContainer>
        <PortalContextProvider portalElementRef={portalRef}>
          <PopperContent $padding={padding}>
            <FlexContainer>
              <ChildrenWrapper>{children}</ChildrenWrapper>
              {withCloseButton && (
              <CloseButton
                aria-label={closeButtonLabel}
                onClick={handleCloseClicked}
                $styleType={type}
                type="button"
                taktFallbackId={createStormTaktId('close-button')}
              >
                <CloseIcon type={times} />
              </CloseButton>
              )}
            </FlexContainer>
            {footer && (
            <FlexContainer>
              {footer}
            </FlexContainer>
            )}
          </PopperContent>
        </PortalContextProvider>
      </TooltipScrollContainer>
      <PortalComponent ref={portalRef} />
    </>
  );
};

PopperContentContainer.propTypes = {
  handleCloseClicked: PT.func.isRequired,
  children: PT.node,
  closeButtonLabel: PT.string,
  footer: PT.node,
  padding: PT.oneOf([
    'none',
    'micro',
    'mini',
    'small',
    'base',
    'medium',
    'large',
    'xlarge',
    'xxlarge',
  ]),
  type: PT.oneOf<ThemeType>(['light', 'dark', 'blue']),
  withCloseButton: PT.bool,
};

PopperContentContainer.defaultProps = {
  children: undefined,
  closeButtonLabel: 'Close Tooltip',
  footer: undefined,
  padding: 'base',
  type: 'light',
  withCloseButton: true,
};

export default PopperContentContainer;
