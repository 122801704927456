import { KeyboardEvent, KeyboardEventHandler } from 'react';
import { getFocusableElements, keyboardKeynames as keys } from '@amzn/storm-ui-utils';

// eslint-disable-next-line import/prefer-default-export
export const handleKeyDownEvents = (
  event: KeyboardEvent<HTMLDivElement | HTMLButtonElement>,
  context: HTMLDivElement | null,
  escapeAction: () => void,
  selectAction?: KeyboardEventHandler<HTMLDivElement | HTMLButtonElement>,
): HTMLElement | null => {
  const { key } = event;
  if (context) {
    const focusableEls = getFocusableElements(context);
    const firstFocusableEl = focusableEls[0];
    const lastFocusableEl = focusableEls[focusableEls.length - 1];

    if (
      key === keys.ARROW_DOWN
      || key === keys.ARROW_UP
      || key === keys.HOME
      || key === keys.END
    ) {
      const currentIndex = focusableEls.indexOf(document.activeElement as HTMLElement);
      let newFocusIndex;
      if (key === keys.ARROW_DOWN) {
        newFocusIndex = currentIndex + 1;
        if (document.activeElement === lastFocusableEl) {
          newFocusIndex = focusableEls.length - 1;
        }
      }
      if (key === keys.ARROW_UP) {
        newFocusIndex = currentIndex - 1;
        if (document.activeElement === firstFocusableEl) {
          newFocusIndex = 0;
        }
      }
      if (key === keys.HOME) {
        newFocusIndex = 0;
      }
      if (key === keys.END) {
        newFocusIndex = focusableEls.length - 1;
      }
      if (typeof newFocusIndex !== 'undefined' && newFocusIndex >= 0) {
        focusableEls[newFocusIndex].focus();
        event.preventDefault();
        return focusableEls[newFocusIndex];
      }
    }
    if (key === keys.ESCAPE) {
      escapeAction();
      event.preventDefault();
    }
    if (key === keys.TAB) {
      // Prevent the tab event in dropdown
      event.preventDefault();
    }
    /**
     * The event target must have the role 'option'
     * in order to allow other interactable elements
     * within dropdowns
     */
    if (
      (key === keys.ENTER || key === keys.SPACE)
      && event.target instanceof HTMLElement
      && event.target.getAttribute('role') === 'option') {
      if (selectAction) {
        selectAction(event);
        event.preventDefault();
      }
    }
  }
  return null;
};
