import React, {
  RefObject,
  FC,
  MouseEvent,
  KeyboardEvent,
  ComponentProps,
  useRef,
  useCallback,
  useEffect,
  ReactNode,
} from 'react';
import {
  getFocusableElements,
  isAndroidFirefox as getIsAndroidFirefox,
  keyboardKeynames as keys,
  MergeElementProps,
  trapTabFocus,
} from '@amzn/storm-ui-utils';
import InlinePortalProvider from '../Portal/InlinePortalProvider';
import {
  CloseButton,
  Shroud,
  SheetContentContainer,
  SheetContentSurface,
  SheetContent,
} from './Sheet.styles';
import { CloseButtonSection } from './CloseButtonSection';
import { SurfaceLight, SurfaceDark, SurfaceDarkInfo } from '../Surface/index';
import {
  MaxSizeProp,
  SheetSide,
  SheetPaddingOptionsPlusUndefined,
} from './types';
import { TransitionStatus } from '../types/react-transition-group/Transition';
import { Surface, SurfaceType } from '../Surface/types';

export function getSurfaceByType(type: SurfaceType): Surface {
  switch (type) {
    case 'dark':
      return SurfaceDark;
    case 'blue':
      return SurfaceDarkInfo;
    default:
      return SurfaceLight;
  }
}

export function createSetBottomToVisualViewport(containerRef: RefObject<HTMLDivElement>):()=>void {
  const isAndroidFirefox = getIsAndroidFirefox();
  return function setBottomToVisualViewport(): void {
    if (typeof window !== 'undefined' && 'visualViewport' in window && window.visualViewport) {
      const { height: viewportHeight } = window.visualViewport;

      if (containerRef && containerRef.current instanceof HTMLDivElement) {
        const { height: containerHeight } = containerRef.current.getBoundingClientRect();
        let offset = 0;
        /**
       * Work-around for Android Firefox bug which causes 56px of whitespace
       * at the bottom of the screen when the Sheet is opened. On resize, if
       * the visualViewport height is greater than the outerHeight of the window, we
       * assume the whitespace is visible and add 56px to the bottom of the Sheet
       * to account for the whitespace blocking any content. Will remove once the
       * browser bug is fixed.
       */
        if (isAndroidFirefox && viewportHeight > window.outerHeight) {
        /**
         * Handle the Android Firefox bug that shifts the body up by 56px when the nav bar is hidden
         */
          offset = -56;
        }

        containerRef.current.style.setProperty('top', `${viewportHeight - containerHeight + offset}px`);
        containerRef.current.style.setProperty('bottom', 'auto');
      }
    }
  };
}

export const Overlay: FC<React.PropsWithChildren<OverlayProps>> = ({
  children,
  className,
  closeButton,
  closeButtonProps,
  modalContainerRef,
  onClose,
  padding = 'base',
  side = 'bottom',
  state,
  type = 'light',
  withCloseButton,
  maxSize = 'default',
  header,
  ...rest
}) => {
  const closeRef = useRef<HTMLButtonElement>(null);

  const handleKeyUp = useCallback((event: KeyboardEvent<HTMLElement>): void => {
    if (event.key === keys.ESCAPE) {
      event.preventDefault();
      event.stopPropagation();
      if (onClose) {
        onClose();
      }
    }
  }, [onClose]);

  const handleKeyDown = useCallback((event: KeyboardEvent): void => {
    if (modalContainerRef.current !== null) {
      trapTabFocus(event, modalContainerRef.current);
    }
  }, [modalContainerRef]);

  const handleClick = useCallback((event: MouseEvent): void => {
    const { target, currentTarget } = event;

    if (target === currentTarget) {
      event.preventDefault();
      if (onClose) {
        onClose();
      }
    }
  }, [onClose]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (modalContainerRef.current) {
      const focusableEls = getFocusableElements(modalContainerRef.current);
      if (focusableEls.length > 0) {
        focusableEls[0].focus();
      }
    }

    if (typeof window !== 'undefined' && 'visualViewport' in window && window.visualViewport) {
      const { visualViewport } = window;

      /**
       * To set the bottom of the sheet to viewport
       * Useful when virtual keyboard is opened
       * Sheet will be pushed up so the keyboard won't cover any content
       */
      const setBottomToVisualViewport = createSetBottomToVisualViewport(modalContainerRef);
      visualViewport.addEventListener('resize', setBottomToVisualViewport);
      setBottomToVisualViewport();

      return () => {
        visualViewport.removeEventListener('resize', setBottomToVisualViewport);
      };
    }
  }, [modalContainerRef]);

  return (
    <Shroud
      className={className}
      onMouseDown={handleClick}
      onKeyUp={handleKeyUp}
      onKeyDown={handleKeyDown}
      role="presentation"
      ref={modalContainerRef}
      $fadeState={state}
    >
      <InlinePortalProvider>
        <SheetContentContainer $slideState={state} side={side}>
          {withCloseButton
            && (
            <CloseButtonSection
              onClose={onClose}
              closeRef={closeRef}
              closeButtonProps={closeButtonProps}
              closeButton={closeButton}
              side={side}
            />
            )}
          <SheetContentSurface
            as={getSurfaceByType(type)}
            $padding={padding}
            side={side}
            $maxSize={maxSize}
          >
            {header}
            <SheetContent
              {...rest}
              $padding={padding}
            >
              {children}
            </SheetContent>
          </SheetContentSurface>
        </SheetContentContainer>
      </InlinePortalProvider>
    </Shroud>
  );
};

export interface OverlayProps extends Omit<MergeElementProps<'div'>, 'ref' | 'type'> {
  className?: string;
  handleClick?: ComponentProps<typeof Shroud>['onMouseDown'];
  handleKeyUp?: ComponentProps<typeof Shroud>['onKeyUp'];
  handleKeyDown?: ComponentProps<typeof Shroud>['onKeyDown'];
  modalContainerRef: RefObject<HTMLDivElement>;
  onClose?: ComponentProps<typeof CloseButton>['onClick'];
  closeButtonProps?: Record<string, unknown>;
  closeButton: {
    type: 'icon' | 'message';
    label: string;
  };
  state: TransitionStatus;
  padding?: SheetPaddingOptionsPlusUndefined;
  side?: SheetSide;
  type?: SurfaceType;
  withCloseButton?: boolean;
  maxSize?: MaxSizeProp;
  header?: ReactNode;
}

Overlay.defaultProps = {
  className: undefined,
  handleClick: undefined,
  handleKeyUp: undefined,
  handleKeyDown: undefined,
  onClose: undefined,
  closeButtonProps: undefined,
  padding: 'base',
  side: 'bottom',
  type: 'light',
  withCloseButton: false,
  maxSize: 'default',
  header: undefined,
};
