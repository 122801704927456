import React, { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import PT from 'prop-types';
import { deviceTypes, getPadding, MergeElementProps } from '@amzn/storm-ui-utils-v3';
import type { TaktProps } from '../types/TaktProps';
import { PaddingOptions } from '../types/PaddingOptions';
import { SectionBgColors, ContentMaxWidth } from './types';
import { defaultTheme } from '../theme';
import isMobile from '../theme/style-mixins/isMobile/isMobile';
import useIsMobile from '../theme/MobileThemeProvider/useIsMobile';
import { mobileSpacing } from '../theme/base/spacing';

interface SectionStyleProps {
  $paddingBlockStart?: PaddingOptions;
  $paddingBlockEnd?: PaddingOptions;
  $marginBlockStart?: PaddingOptions;
  $marginBlockEnd?: PaddingOptions;
  $bgColor?: SectionBgColors;
  $paddingInline?: PaddingOptions;
  $isMobile?: boolean;
}

interface ContentStyleProps {
  $contentMaxWidth?: ContentMaxWidth;
}

const getContainerContentMaxWidth = (size: ContentMaxWidth = 'fluid'): string => {
  if (defaultTheme
    && defaultTheme.pageLayout
    && typeof defaultTheme.pageLayout.section.contentMaxWidth[size] !== 'undefined'
  ) {
    return defaultTheme.pageLayout.section.contentMaxWidth[size];
  }
  return '100%';
}

const getBgColor = (color: SectionBgColors = 'default'): string => {
  if (defaultTheme
    && defaultTheme.pageLayout
    && typeof defaultTheme.pageLayout.section.background[color] !== 'undefined'
  ) {
    return defaultTheme.pageLayout.section.background[color];
  }
  return '#FFFFFF';
}

const applyPaddingInlineDefault = css<SectionStyleProps>`
  padding-inline-start: ${({ $isMobile, theme }) => ($isMobile ? mobileSpacing.base : theme.spacing.large)};
  padding-inline-end: ${({ $isMobile, theme }) => ($isMobile ? mobileSpacing.base : theme.spacing.large)};
`;

const applyPaddingInline = () => css<SectionStyleProps>`
  padding-inline-start: ${({ $paddingInline, theme }) => getPadding(theme, $paddingInline)}px;
  padding-inline-end: ${({ $paddingInline, theme }) => getPadding(theme, $paddingInline)}px;

  ${isMobile(css<SectionStyleProps>`
    padding-inline-start: ${({ $paddingInline, theme }) => getPadding(theme, $paddingInline, deviceTypes.MOBILE)}px;
    padding-inline-end: ${({ $paddingInline, theme }) => getPadding(theme, $paddingInline, deviceTypes.MOBILE)}px;
  `)}
`;

const Container = styled('div')<SectionStyleProps>`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;

  // if paddingInline is not explicitly set, provide spacing values of
  // desktop: 22px
  // mobile: 14px
  ${({ $paddingInline }) => ($paddingInline ? applyPaddingInline() : applyPaddingInlineDefault)}

  padding-block-start: ${({ $paddingBlockStart, theme }) => getPadding(theme, $paddingBlockStart)}px;
  padding-block-end: ${({ $paddingBlockEnd, theme }) => getPadding(theme, $paddingBlockEnd)}px;
  margin-block-start: ${({ $marginBlockStart, theme }) => getPadding(theme, $marginBlockStart)}px;
  margin-block-end: ${({ $marginBlockEnd, theme }) => getPadding(theme, $marginBlockEnd)}px;

  ${isMobile(css<SectionStyleProps>`
    padding-block-start: ${({ $paddingBlockStart, theme }) => getPadding(theme, $paddingBlockStart, deviceTypes.MOBILE)}px;
    padding-block-end: ${({ $paddingBlockEnd, theme }) => getPadding(theme, $paddingBlockEnd, deviceTypes.MOBILE)}px;
    margin-block-start: ${({ $marginBlockStart, theme }) => getPadding(theme, $marginBlockStart, deviceTypes.MOBILE)}px;
    margin-block-end: ${({ $marginBlockEnd, theme }) => getPadding(theme, $marginBlockEnd, deviceTypes.MOBILE)}px;
  `)}

  background: ${({ $bgColor }) => getBgColor($bgColor)};
`;

const Content = styled('div')<ContentStyleProps>`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  width: 100%;
  box-sizing: border-box;

  max-width: ${({ $contentMaxWidth }) => getContainerContentMaxWidth($contentMaxWidth)};
`;

export interface SectionProps extends TaktProps, Omit<MergeElementProps<'div'>, 'ref'> {
  /**
   * Content of the Section
   */
  children: ReactNode;
  /**
   * Logical block start padding (top padding)
   * @defaultValue `"large"`
   */
  paddingBlockStart?: PaddingOptions;
  /**
   * Logical block end padding (bottom padding)
   * @defaultValue `"large"`
   */
  paddingBlockEnd?: PaddingOptions;
  /**
   * Logical block start margin (top margin)
   * @defaultValue `"none"`
   */
  marginBlockStart?: PaddingOptions;
  /**
   * Logical block end margin (bottom margin)
   * @defaultValue `"none"`
   */
  marginBlockEnd?: PaddingOptions;
  /**
   * Background color
   * @defaultValue `"default"`
   */
  bgColor?: SectionBgColors;
  /**
   * Left and right padding around section content. Most sections should use `large`
   * @defaultValue `undefined`
   */
  paddingInline?: PaddingOptions;
  /**
   * Max width of Section content
   * @defaultValue `"fluid"`
   */
  contentMaxWidth?: ContentMaxWidth;
}

const Section: FC<SectionProps> = ({
  children,
  paddingBlockStart,
  paddingBlockEnd,
  marginBlockStart,
  marginBlockEnd,
  bgColor,
  paddingInline,
  contentMaxWidth,
  ...rest
}) => {
  const isMobilePlatform = useIsMobile();

  return (
    <Container
      {...rest}
      $paddingBlockStart={paddingBlockStart}
      $paddingBlockEnd={paddingBlockEnd}
      $marginBlockStart={marginBlockStart}
      $marginBlockEnd={marginBlockEnd}
      $bgColor={bgColor}
      $paddingInline={paddingInline}
      $isMobile={isMobilePlatform}
    >
      <Content $contentMaxWidth={contentMaxWidth}>{children}</Content>
    </Container>
  )
};

Section.propTypes = {
  /**
   * Content of the Section
   */
  children: PT.node.isRequired,
  /**
   * Logical block start padding (top padding)
   */
  paddingBlockStart: PT.oneOf([
    'none',
    'micro',
    'mini',
    'small',
    'base',
    'medium',
    'large',
    'xlarge',
    'xxlarge',
  ]),
  /**
   * Logical block end padding (bottom padding)
   */
  paddingBlockEnd: PT.oneOf([
    'none',
    'micro',
    'mini',
    'small',
    'base',
    'medium',
    'large',
    'xlarge',
    'xxlarge',
  ]),
  /**
   * Logical block start margin (top margin)
   */
  marginBlockStart: PT.oneOf([
    'none',
    'micro',
    'mini',
    'small',
    'base',
    'medium',
    'large',
    'xlarge',
    'xxlarge',
  ]),
  /**
   * Logical block end margin (bottom margin)
   */
  marginBlockEnd: PT.oneOf([
    'none',
    'micro',
    'mini',
    'small',
    'base',
    'medium',
    'large',
    'xlarge',
    'xxlarge',
  ]),
  /**
   * Background color
   */
  bgColor: PT.oneOf([
    'default',
    'offset',
  ]),
  /**
   * Left and right padding around section content. Most sections should use `large`
   */
  paddingInline: PT.oneOf([
    'none',
    'micro',
    'mini',
    'small',
    'base',
    'medium',
    'large',
    'xlarge',
    'xxlarge',
  ]),
  /**
   * Max width of Section content
   */
  contentMaxWidth: PT.oneOf([
    'xsmall',
    'small',
    'base',
    'large',
    'fluid',
  ]),
}

Section.defaultProps = {
  paddingBlockStart: 'large',
  paddingBlockEnd: 'large',
  marginBlockStart: 'none',
  marginBlockEnd: 'none',
  bgColor: 'default',
  paddingInline: undefined,
  contentMaxWidth: 'fluid',
}

export default Section;
