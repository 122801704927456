import React, {
  PureComponent,
  createElement,
  ReactNode,
  ChangeEventHandler,
  Ref,
  FocusEventHandler,
  createRef,
  ComponentType,
} from 'react';
import PT, { Validator } from 'prop-types';
import styled from 'styled-components';
import {
  createPseudoUID,
  getFirstFocusableElement,
  isFunction,
  isString,
  memoizeWeak,
  MergeElementProps,
  noop,
} from '@amzn/storm-ui-utils';
import HelpTip from '../HelpTip/HelpTip';

import formGroupRowStyleMixin from '../FormGroup/formGroupRowStyleMixin';
import formGroupColumnStyleMixin from '../FormGroup/formGroupColumnStyleMixin';
import LabelLine from '../FormGroup/LabelLine';
import ValidationLine from '../FormGroup/ValidationLine';
import Prefix from './Prefix';
import Suffix from './Suffix';
import { InputValidationMessage, InputGroup, styleInputMixin } from './InputFormGroup.styles';
import isMobile from '../theme/style-mixins/isMobile/isMobile';
import { StatusType, RenderMessage } from './types';
import { TaktProps } from '../types/TaktProps';
import { TaktIdConsumer, createStormTaktId } from '../TaktIdContext';

export const renderInputValidationMessage = (
  value: string | number | readonly string[] | undefined,
  messageId: string,
  message: string | RenderMessage,
  messageE2EId?: string,
  statusType?: StatusType,
) => (
  <ValidationLine
    role="alert"
    aria-live="assertive"
    id={messageId}
  >
    {typeof message === 'function'
      ? message(value)
      : (
        <InputValidationMessage
          messageType={statusType}
          message={message}
          data-e2e-id={messageE2EId}
        />
      )}
  </ValidationLine>
);

const NodeSafeInputElement = (typeof HTMLInputElement !== 'undefined' ? HTMLInputElement : Object);

/*
 * Creating styled components are expensive. Caching the results
 * so that we don't need to create new ones unnecessary.
 */
// eslint-disable-next-line max-len
const createStyledInput = memoizeWeak((inputElement: ComponentType<unknown> | 'input') => {
  const styledInput = styled(
    ({
      /*
       * Exclude the props that are only passed to the style. If The props were passed to the
       * react node, they would be attached to the DOM node and throw a warning.
       */
      prefix,
      suffix,
      inputRef,
      ...inputProps
    }) => createElement(
      inputElement,
      {
        ...inputProps,
        // default html input vs custom inputElement
        ...(inputElement === 'input' ? { ref: inputRef } : { inputRef }),
      },
      inputProps.children,
    ),
  )`${styleInputMixin}`;
  styledInput.displayName = 'Input';
  return styledInput;
});

const InputFormGroupPropTypes = {
  /**
   * he page unique identifier of the input.
   */
  id: PT.string.isRequired,
  /**
   * The class name passed to the wrapper div element.
   */
  className: PT.string,
  /**
   * Allows the `<input />` element to be replaced with a custom element.
   */
  inputElement: PT.any,
  /**
   * Denotes if the InputFormGroup should be disabled.
   */
  disabled: PT.bool,
  /**
   * The function called on input change.
   */
  onChange: PT.func,
  /**
   * Labels are required for screen reader accessibility, but can be hidden visually.
   */
  hideLabel: PT.bool,
  /**
   * Needed for accessibility when no other label is associated to the input.
   * See "Hiding labels" above to visually hide the label.
   */
  label: PT.node,
  /**
   * Used to render content *before* the label, but outside the click-able area.
   */
  renderLabelStart: PT.oneOfType([PT.func, PT.node]),
  /**
   * Used to render content *after* the label, but outside the click-able area.
   */
  renderLabelEnd: PT.oneOfType([PT.func, PT.node]),
  /**
   * Label and message will be inlined.
   */
  inline: PT.bool,
  /**
   * A placeholder value for the input area.
   */
  placeholder: PT.string,
  /**
   * Adds a message below the input, which will update based on error/success/warning.
   */
  message: PT.oneOfType([PT.string, PT.func]),
  /**
   * Specifies the type of message to be displayed (error, warning, success)
   */
  statusType: PT.oneOf<StatusType>(['error', 'warning', 'success']),
  /**
   * Use to match the width of the container.
   * Useful for setting specific widths (by setting width on container).
   */
  fullWidth: PT.bool,
  /**
   * Use a string of text or a React element (Such as <Icon type="search" />).
   */
  prefix: PT.node,
  /**
   * Use a string of text or a React element (Such as <Icon type="search" />).
   */
  suffix: PT.node,
  type: PT.string,
  /**
   * Use to access the <input> element ref.
   */
  inputRef: PT.oneOfType([
    PT.func,
    PT.shape(
      { current: (PT.instanceOf(NodeSafeInputElement)) },
    ) as Validator<Ref<HTMLInputElement>>,
  ]),
  /**
   * *Prop will be deprecated in a future version, use renderLabelEnd with `<HelpTip/>` instead.*
   * Adds an info tooltip with your help message (can pass a string or node).
   */
  help: PT.node,
  /**
   * A data-id that can be used for writing unit tests.
   */
  messageE2EId: PT.string,
  /**
   * The function called on input focus.
   */
  onFocus: PT.func,
  /**
   * The function called on input blur.
   */
  onBlur: PT.func,
  /**
   * Set the aria-label attribute on input.
   */
  ariaLabel: PT.string,
  /**
   * Ignores the HTML dir attribute when determining placement of prefix and suffix elements. This is useful for
   * displaying currencies where the placement of the symbol is the same regardless of writing direction.
   */
  ignoreTextDirection: PT.bool,
};

const InputFormGroupDefaultProps = {
  inputElement: 'input',
  disabled: false,
  type: 'text',
  onChange: noop,
  label: undefined,
  hideLabel: false,
  renderLabelStart: undefined,
  renderLabelEnd: undefined,
  inline: false,
  placeholder: '',
  message: '',
  statusType: undefined,
  fullWidth: false,
  prefix: undefined,
  suffix: undefined,
  help: undefined,
  inputRef: undefined,
  messageE2EId: undefined,
  onFocus: undefined,
  onBlur: undefined,
  ariaLabel: undefined,
  className: '',
  ignoreTextDirection: undefined,
};

export interface InputFormGroupProps extends TaktProps, Omit<MergeElementProps<'input'>, 'id'|'prefix'|'ref'> {
  /**
   * he page unique identifier of the input.
   */
  id: string;
  /**
   * The class name passed to the wrapper div element.
   * @defaultValue `""`
   */
  className?: string;
  /**
   * Allows the `<input />` element to be replaced with a custom element.
   * @defaultValue `"input"`
   */
  inputElement?: string | ComponentType<unknown>;
  /**
   * Denotes if the InputFormGroup should be disabled.
   * @defaultValue `false`
   */
  disabled?: boolean;
  /**
   * The function called on input change.
   * @defaultValue `() => undefined`
   */
  onChange?: ChangeEventHandler<HTMLInputElement>;
  /**
   * Labels are required for screen reader accessibility, but can be hidden visually.
   * @defaultValue `false`
   */
  hideLabel?: boolean;
  /**
   * Needed for accessibility when no other label is associated to the input.
   * See "Hiding labels" above to visually hide the label.
   * @defaultValue `undefined`
   */
  label?: ReactNode;
  /**
   * Used to render content *before* the label, but outside the click-able area.
   * @defaultValue `undefined`
   */
  renderLabelStart?: ReactNode | (() => ReactNode) | (() => JSX.Element);
  /**
   * Used to render content *after* the label, but outside the click-able area.
   * @defaultValue `undefined`
   */
  renderLabelEnd?: ReactNode | (() => ReactNode) | (() => JSX.Element);
  /**
   * Label and message will be inlined.
   * @defaultValue `false`
   */
  inline?: boolean;
  /**
   * A placeholder value for the input area.
   * @defaultValue `""`
   */
  placeholder?: string;
  /**
   * Adds a message below the input, which will update based on error/success/warning.
   * @defaultValue `""`
   */
  message?: string | RenderMessage;
  /**
   * Specifies the type of message to be displayed (error, warning, success)
   * @defaultValue `undefined`
   */
  statusType?: StatusType;
  /**
   * Use to match the width of the container.
   * Useful for setting specific widths (by setting width on container).
   * @defaultValue `false`
   */
  fullWidth?: boolean;
  /**
   * Use a string of text or a React element (Such as <Icon type="search" />).
   * @defaultValue `undefined`
   */
  prefix?: ReactNode;
  /**
   * Use a string of text or a React element (Such as <Icon type="search" />).
   * @defaultValue `undefined`
   */
  suffix?: ReactNode;
  /**
   * @defaultValue `"text"`
   */
  type?: string;
  /**
   * Use to access the <input> element ref.
   * @defaultValue `undefined`
   */
  inputRef?: Ref<HTMLInputElement>;
  /**
   * *Prop will be deprecated in a future version, use renderLabelEnd with `<HelpTip/>` instead.*
   * Adds an info tooltip with your help message (can pass a string or node).
   * @defaultValue `undefined`
   */
  help?: ReactNode;
  /**
   * A data-id that can be used for writing unit tests.
   * @defaultValue `undefined`
   */
  messageE2EId?: string;
  /**
   * The function called on input focus.
   * @defaultValue `undefined`
   */
  onFocus?: FocusEventHandler<HTMLInputElement>;
  /**
   * The function called on input blur.
   * @defaultValue `undefined`
   */
  onBlur?: FocusEventHandler<HTMLInputElement>;
  /**
   * Set the aria-label attribute on input.
   * @defaultValue `undefined`
   */
  ariaLabel?: string;
  /**
   * Ignores the HTML dir attribute when determining placement of prefix and suffix elements. This is useful for
   * displaying currencies where the placement of the symbol is the same regardless of writing direction.
   * @defaultValue `undefined`
   */
  ignoreTextDirection?: boolean;
}

class InputFormGroupComponent extends PureComponent<InputFormGroupProps, { ephemeralId?: string }> {
  static displayName = 'InputFormGroupComponent'

  static propTypes = InputFormGroupPropTypes;

  static defaultProps = InputFormGroupDefaultProps;

  private inputGroup = createRef<HTMLInputElement>();

  styledInput: any;

  constructor(props: InputFormGroupProps) {
    super(props);

    this.state = {
      ephemeralId: undefined,
    };
  }

  componentDidMount() {
    const {
      id,
    } = this.props;

    /*
     * create a ephemeral message Id after mounted without a ID and the validation line has
     * been created
     */
    if (!isString(id)) {
      this.setEphemeralId();
    }
  }

  componentDidUpdate(prevProps: InputFormGroupProps) {
    const {
      id,
    } = this.props;

    /*
     * Deals with the edge case when a id prop is set or cleared when the validation line is
     * visible
     */
    if (id !== prevProps.id) {
      if (!isString(id)) {
        this.setEphemeralId();
      } else {
        this.clearEphemeralId();
      }
    }
  }

  setEphemeralId = () => {
    const { ephemeralId } = this.state;
    // prevent changing the ephemeralId if one was already set
    if (!isString(ephemeralId)) {
      this.setState(() => ({ ephemeralId: createPseudoUID() }));
    }
  }

  clearEphemeralId = () => {
    this.setState(() => ({ ephemeralId: undefined }));
  }

  renderLabelEndWithHelptip = () => {
    const {
      renderLabelEnd,
      help,
    } = this.props;
    return (
      <>
        {isFunction<() => ReactNode>(renderLabelEnd) && renderLabelEnd()}
        <HelpTip position="right">{help}</HelpTip>
      </>
    );
  }

  focusInput = () => {
    if (this.inputGroup.current) {
      const focusable = getFirstFocusableElement(this.inputGroup.current);
      if (focusable instanceof HTMLElement
        && isFunction<(options?: unknown) => void>(focusable.focus)) {
        focusable.focus();
      }
    }
  }

  render(): JSX.Element {
    const {
      id,
      className,
      disabled,
      label,
      ariaLabel,
      message,
      messageE2EId,
      statusType,
      prefix,
      suffix,
      fullWidth,
      inline,
      hideLabel,
      inputRef,
      help,
      inputElement,
      renderLabelStart,
      renderLabelEnd,
      value,
      ignoreTextDirection,
      taktId,
      taktValue,
      ...rest
    } = this.props;
    const {
      ephemeralId,
    } = this.state;
    this.styledInput = createStyledInput(inputElement);

    const labelId = isString(id) ? undefined : `${ephemeralId}-input-label`;
    const messageId = isString(id) ? `${id}-input-message` : `${ephemeralId}-input-message`;

    const a11yProps = {
      ...((ariaLabel) ? { 'aria-label': ariaLabel } : {}),
      /**
      * When custom aria-label or `id` prop is provided,
      * we do not need to do the labelledby work around
      */
      ...(!ariaLabel && isString(labelId) ? { 'aria-labelledby': labelId } : {}),
      /**
      * Add a 'aria-describedby' when any message is applied to the input
      */
      ...((message) ? { 'aria-describedby': messageId } : {}),
      /**
      * Error get a `'aria-invalid="true"`
      */
      ...((statusType === 'error') ? { 'aria-invalid': true } : {}),
    };

    return (
      <TaktIdConsumer taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('input-form-group')}>
        {({ getDataTaktAttributes }) => (
          <div className={className}>
            { label && (
            <LabelLine
              hidden={hideLabel}
              renderLabelStart={renderLabelStart}
              renderLabelEnd={
                /**
                 * Support the `help` prop by folding it into renderLabelEnd
                 */
                help ? this.renderLabelEndWithHelptip : renderLabelEnd
              }
              {
                /**
                 * When `id` prop is provided, we do not need to do the labelledby work around and
                 * simulate `<label for="...">` click behavior
                 */
                ...(isString(id)
                  ? { labelFor: id }
                  : {
                    labelId,
                    onLabelClick: this.focusInput,
                  })
              }
              {...getDataTaktAttributes({ taktIdSuffix: 'label' })}
            >
                {label}
            </LabelLine>
            )}
            <InputGroup
              fullWidth={fullWidth}
              prefix={prefix}
              suffix={suffix}
              $statusType={statusType}
              inputGroupRef={this.inputGroup}
              dir={ignoreTextDirection ? 'ltr' : undefined}
              disabled={disabled}
            >
              {prefix && (
                <Prefix
                  $ignoreTextDirection={ignoreTextDirection}
                  disabled={disabled}
                >
                    {prefix}
                </Prefix>
              )}
              {
              /*
              * Using the non-JSX `React.createElement()` lets us choose between the named DOM
              * element and a function that returns a node, without adding additional flow control.
              */
              createElement(
                this.styledInput,
                {
                  ...getDataTaktAttributes(),
                  ...rest,
                  ...(isString(id) ? { id } : {}),
                  ...a11yProps,
                  value,
                  inputRef,
                  disabled,
                  prefix,
                  suffix,
                  $statusType: statusType,
                  $ignoreTextDirection: ignoreTextDirection,
                },
                null, /* children are null when assuming a closed tag like `<Input />` */
              )
            }
              {suffix && (
                <Suffix
                  $ignoreTextDirection={ignoreTextDirection}
                  disabled={disabled}
                >
                  {suffix}
                </Suffix>
              )}
            </InputGroup>
            { (!!message) && renderInputValidationMessage(value, messageId, message, messageE2EId, statusType)}
          </div>
        )}
      </TaktIdConsumer>

    );
  }
}

const InputFormGroup = styled(InputFormGroupComponent)<InputFormGroupProps>`
  ${({ inline }) => (inline ? formGroupRowStyleMixin : formGroupColumnStyleMixin)}
  ${isMobile(formGroupColumnStyleMixin)}
`;

InputFormGroup.displayName = 'InputFormGroup';
InputFormGroup.propTypes = InputFormGroupPropTypes;
InputFormGroup.defaultProps = InputFormGroupDefaultProps;

export default InputFormGroup;
