import React, {
  FC, ReactNode, MouseEventHandler,
} from 'react';
import PT from 'prop-types';
import styled from 'styled-components';
import { times } from '@amzn/storm-ui-icons';
import { TextButton } from '../Button';
import Icon from '../Icon';
import { createStormTaktId, withTaktFallbackId } from '../TaktIdContext';
import { TextButtonProps } from '../Button/TextButton';

const TooltipContent = styled.div`
  padding: ${({ theme }) => theme.tooltip.paddingTopBottom} ${({ theme }) => theme.tooltip.paddingLeftRight};

  overflow: visible;
  max-width: ${({ theme }) => theme.tooltip.maxWidth};
  max-height: ${({ theme }) => theme.tooltip.maxHeight};
`;
TooltipContent.displayName = 'TooltipContent';

const TooltipContentWithTopCornerIcon = styled(TooltipContent)`
  padding: ${({ theme }) => theme.tooltip.paddingTopBottomWithTopCornerIcon} ${({ theme }) => theme.tooltip.paddingLeftRightWithTopCornerIcon};
`;
TooltipContentWithTopCornerIcon.displayName = 'AnchoredAlertContentWithTopCornerIcon';

const TooltipScrollContainer = styled.div`
  overflow-x: auto;
`;
TooltipScrollContainer.displayName = 'TooltipScrollContainer';

const TooltipMessage = styled.div`
  ${({ theme }) => theme.typography.base};
`;
TooltipMessage.displayName = 'TooltipMessage';

type ThemeType = 'light' | 'dark' | 'blue';
interface StyledCloseButtonBaseProps extends TextButtonProps {
  $styleType: ThemeType;
}
const CloseButtonBase = styled(TextButton)<StyledCloseButtonBaseProps>`
  opacity: ${({ $styleType }) => (($styleType === 'blue' || $styleType === 'dark') ? 1 : 0.5)};
  padding: ${({ theme }) => theme.tooltip.closeIcon.padding};
  text-align: center;
  color: ${({ $styleType, theme }) => (theme.tooltip.closeIcon[$styleType])};
  outline: none;
  margin-inline-start: ${({ theme }) => theme.tooltip.closeIcon.marginLeft};

  :hover{
    opacity: 1;
    background: ${({ $styleType, theme }) => (theme.tooltip.closeIconBg[$styleType])};
    color: ${({ $styleType, theme }) => (theme.tooltip.closeIcon[$styleType])};
  }

  @media (prefers-reduced-motion: no-preference) {
    transition: background .2s ease-in-out;
  }

  :focus {
    opacity: 1;
  }
`;

const CloseButton = withTaktFallbackId<StyledCloseButtonBaseProps>(CloseButtonBase);

const NoMarginCloseIcon = styled(CloseButton)`
  margin: 0;
  margin-inline-start: auto;
`;

const NoMarginCloseButton = withTaktFallbackId<StyledCloseButtonBaseProps>(NoMarginCloseIcon);

const CloseIcon = styled(Icon)`
  line-height: 1;
  margin: 0;
  padding: 0;
  display: inline-block;

  .svg-inline--fa{
    vertical-align: ${({ theme }) => theme.tooltip.closeIcon.svg.verticalAlign};
    width: ${({ theme }) => theme.tooltip.closeIcon.svg.width};
  }
`;
CloseIcon.displayName = 'CloseIcon';

const FlexContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

const ContentContainerWithTopCornerIcon = styled(FlexContainer)`
  padding: ${({ theme }) => theme.tooltip.contentPaddingTopBottomWithTopCornerIcon} ${({ theme }) => theme.tooltip.contentPaddingLeftRightWithTopCornerIcon};
`;
ContentContainerWithTopCornerIcon.displayName = 'ContentContainerWithTopCornerIcon';

export interface TooltipContentProps {
  type?: ThemeType;
  message: string | ReactNode;
  messageId?: string;
  withCloseButton?: boolean;
  closeButtonLabel?: string;
  handleCloseClicked: MouseEventHandler<HTMLButtonElement>;
  footer?: ReactNode;
  children?: ReactNode;
  closeIconPosition?: 'default' | 'topCorner';
}

const TooltipContentContainer: FC<React.PropsWithChildren<TooltipContentProps>> = ({
  children,
  closeButtonLabel,
  handleCloseClicked,
  footer,
  message,
  messageId,
  type = 'light',
  withCloseButton,
  closeIconPosition,
}) => {
  const DefaultLayout = (
    <TooltipContent>
      <FlexContainer>
        <div id={messageId}>{message ? <TooltipMessage>{message}</TooltipMessage> : children}</div>
        {withCloseButton && (
          <CloseButton
            aria-label={closeButtonLabel}
            onClick={handleCloseClicked}
            $styleType={type}
            type="button"
            taktFallbackId={createStormTaktId('close-button')}
          >
            <CloseIcon type={times} />
          </CloseButton>
        )}
      </FlexContainer>
      {footer && (
        <FlexContainer>
          {footer}
        </FlexContainer>
      )}
    </TooltipContent>
  );

  const TopCornerCloseIconLayout = (
    <TooltipContentWithTopCornerIcon>
      <FlexContainer>
        <NoMarginCloseButton
          aria-label={closeButtonLabel}
          onClick={handleCloseClicked}
          $styleType={type}
          type="button"
          taktFallbackId={createStormTaktId('close-button')}
        >
          <CloseIcon type={times} />
        </NoMarginCloseButton>
      </FlexContainer>
      <ContentContainerWithTopCornerIcon>
        <div>{message ? <TooltipMessage>{message}</TooltipMessage> : children}</div>
      </ContentContainerWithTopCornerIcon>
      { footer && (
        <FlexContainer>
          { footer }
        </FlexContainer>
      )}
    </TooltipContentWithTopCornerIcon>
  );

  return (
    <TooltipScrollContainer>
      {closeIconPosition === 'topCorner' ? TopCornerCloseIconLayout : DefaultLayout}
    </TooltipScrollContainer>
  );
};

TooltipContentContainer.propTypes = {
  handleCloseClicked: PT.func.isRequired,
  children: PT.node,
  closeButtonLabel: PT.string,
  footer: PT.node,
  message: PT.oneOfType([
    PT.string,
    PT.node,
  ]),
  type: PT.oneOf<ThemeType>(['light', 'dark', 'blue']),
  withCloseButton: PT.bool,
  closeIconPosition: PT.oneOf(['default', 'topCorner']),
};

TooltipContentContainer.defaultProps = {
  children: undefined,
  closeButtonLabel: 'Close Tooltip',
  footer: undefined,
  message: '',
  messageId: undefined,
  type: 'light',
  withCloseButton: true,
  closeIconPosition: 'default',
};

export default TooltipContentContainer;
