import React, { useState, useEffect, RefObject } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import useGetPortal from './useGetPortal';
import useStormError from '../ErrorIdProvider/useStormError';

export interface PortalProps {
  children: React.ReactNode;
  containerId?: string;
  disabled?: boolean;
  externalPortalRef?: RefObject<HTMLElement>;
  transitionDuration?: number;
}

const NodeSafeHTMLElement = (typeof HTMLElement !== 'undefined' ? HTMLElement : Object) as typeof HTMLElement;

/**
 * Portals provide a first-class way to render children into a DOM node
 * that exists outside the DOM hierarchy of the parent component.
 */
const Portal: React.FC<React.PropsWithChildren<PortalProps>> = ({
  children,
  containerId,
  disabled,
  externalPortalRef,
  transitionDuration,
}) => {
  /** Get the current portal ref */
  const portalRef = useGetPortal(containerId, externalPortalRef);

  const { throwStormError } = useStormError();

  /**
   * The `portalMounted` and `useEffect()` prevents react from attempting to use the portal
   * before it has been committed to the DOM. The `portalRef.current` will sometimes be `null` \
   * until after the initial render.
   */
  const [portalMounted, setPortalMounted] = useState<boolean>(!!portalRef?.current);
  useEffect(() => {
    setPortalMounted(true);
    let timeout: ReturnType<typeof setTimeout>;
    const bodyRect = document?.body?.getBoundingClientRect();
    // this prevents this check from happening in a headless environment
    if (bodyRect && bodyRect.height > 0 && bodyRect.width > 0) {
      timeout = setTimeout(() => {
        if (portalRef && !portalRef?.current) {
          throwStormError('portal', 'The portal element is currently null or undefined. Ensure the portal element is valid.');
        }
        if (portalRef && portalRef.current && portalRef.current.children?.length > 0) {
          const firstChild = portalRef?.current?.children?.[0]?.getBoundingClientRect();
          if (firstChild && firstChild.height === 0 && firstChild.width === 0) {
            throwStormError('portal', 'The portal element is currently invisible, either due to its own styles or its parent\'s styles. Ensure the element and its parents are visible in the DOM.');
          }
        }
        // if a duration is set, wait for the popover to mount
      }, transitionDuration ?? 0);
    }
    return () => {
      clearTimeout(timeout);
    }
  }, [portalRef, throwStormError, transitionDuration]);

  // Skip using Portal when disabled or `portalRef` is not defined
  if (disabled || !portalRef) {
    return <>{ children }</>;
  }

  // When portalRef.current becomes available is it as the portal
  if (portalMounted && portalRef.current) {
    return createPortal(children, portalRef.current);
  }

  // Do not render any children when the `portalRef.current` is null.
  return null;
};

Portal.propTypes = {
  /**
   * The children to render into the `Portal`.
   */
  children: PropTypes.node.isRequired,
  /**
   * The Id of the element into which the `children` should be rendered.
   */
  containerId: PropTypes.string,
  /**
   * Disables the portal and renders the contents of the portal in place in the DOM.
   */
  disabled: PropTypes.bool,
  /**
   * A React Ref that contains the HTMLElement this popper will be rendered into.
   */
  externalPortalRef: PropTypes.shape({
    current: (PropTypes.instanceOf(NodeSafeHTMLElement)),
  }) as React.Validator<React.RefObject<HTMLElement>>,
  /**
  * The duration for the portal to appear
  */
  transitionDuration: PropTypes.number,
};

Portal.defaultProps = {
  containerId: '',
  disabled: false,
  externalPortalRef: undefined,
  transitionDuration: 0,
};

export default Portal;
