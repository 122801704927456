/* eslint-disable quotes, quote-props, comma-dangle  */
import type { TranslationProps } from './types';

export const translations = {
  "action-attach": [
    {
      "type": 0,
      "value": "Attach file"
    }
  ],
  "action-send": [
    {
      "type": 0,
      "value": "Send message"
    }
  ],
  "attachment-count-error": [
    {
      "type": 0,
      "value": "Too many attachments - "
    },
    {
      "type": 1,
      "value": "n"
    },
    {
      "type": 0,
      "value": " maximum"
    }
  ],
  "attachment-size-error": [
    {
      "type": 1,
      "value": "attachment"
    },
    {
      "type": 0,
      "value": " too large - 5MB maximum"
    }
  ],
  "remove-attachment": [
    {
      "type": 0,
      "value": "Remove attached file "
    },
    {
      "type": 1,
      "value": "attachment"
    }
  ],
  "thumbnail-alt": [
    {
      "type": 0,
      "value": "Open modal preview of "
    },
    {
      "type": 1,
      "value": "attachment"
    }
  ]
};

function TranslationProviderComponent({ children }: TranslationProps) {
  return children(translations);
}

export default Object.assign(TranslationProviderComponent, { translations });
