import React, {
  ChangeEvent,
  ReactNode,
} from 'react';
import { noop } from '@amzn/storm-ui-utils-v3';

type RadioGroupContext = {
  isGroupDisabled?: boolean;
  handleRadioChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isGroupInline?: boolean;
  name: string;
  selectedValue?: string;
}

export const defaultContextValue = {
  handleRadioChange: noop,
  name: '',
  isGroupDisabled: undefined,
  isGroupInline: undefined,
  selectedValue: undefined,
};

const RadioGroupContext = React.createContext<RadioGroupContext>(defaultContextValue);

export default RadioGroupContext;

export interface RadioGroupProvider extends RadioGroupContext {
  children: ReactNode;
}

// Internal use only (RadioGroup & Dropdown)
export const RadioGroupProvider = ({
  handleRadioChange,
  isGroupInline,
  isGroupDisabled,
  name,
  selectedValue,
  children,
}: RadioGroupProvider) => (
  <RadioGroupContext.Provider
    value={{
      handleRadioChange,
      isGroupInline,
      isGroupDisabled,
      name,
      selectedValue,
    }}
  >
    {children}
  </RadioGroupContext.Provider>
)
RadioGroupProvider.defaultProps = {
  isGroupDisabled: undefined,
  isGroupInline: undefined,
  selectedValue: undefined,
};
