import React, {
  useEffect,
  RefObject,
  ReactNode,
  useRef,
  forwardRef,
} from 'react';
import styled from 'styled-components';
import {
  composeRefs,
  elementRevealsVirtualKeyboardOnFocus,
  isAndroidFirefox as getIsAndroidFirefox,
} from '@amzn/storm-ui-utils-v3';
import { TransitionStatus } from 'react-transition-group';
import CloseButton, { CloseButtonProps } from '../Button/CloseButton/CloseButton';
import DrawerSurface from './DrawerSurface';
import { createStormTaktId, withTaktFallbackId } from '../TaktIdContext';

/**
 * Position the close button and ensure RTL is handled.
 */
export const PositionedCloseButton = styled(CloseButton)`
  position: absolute;

  /* @noflip */ left: auto;
  inset-inline-start: auto;
  /* @noflip */ right: ${({ theme }) => theme.spacing.base};
  inset-inline-end: ${({ theme }) => theme.spacing.base};

  [dir="rtl"] && {
    /* @noflip */ right: auto;
    inset-inline-start: auto;
    /* @noflip */ left: ${({ theme }) => theme.spacing.base};
    inset-inline-end: ${({ theme }) => theme.spacing.base};
  }
`;

const androidFirefoxBodyOverflowHiddenGap = 56;

export function createSetBottomToVisualViewport(divRef: RefObject<HTMLDivElement>): () => void {
  const isAndroidFirefox = getIsAndroidFirefox();
  return function setBottomToVisualViewport(): void {
    if (divRef.current instanceof HTMLElement && window.visualViewport) {
      const { height: viewportHeight } = window.visualViewport;
      const { outerHeight, innerHeight } = window;

      const divStyle = divRef.current.style;

      if (isAndroidFirefox) {
        const isVirtualKeyboard = elementRevealsVirtualKeyboardOnFocus(document.activeElement);

        if (isVirtualKeyboard) {
          divStyle.setProperty('bottom', `${viewportHeight - innerHeight + androidFirefoxBodyOverflowHiddenGap}px`);
          return;
        }

        if (viewportHeight > outerHeight && !isVirtualKeyboard) {
          divStyle.setProperty('bottom', `${androidFirefoxBodyOverflowHiddenGap}px`);
          return;
        }
      }

      if (innerHeight > viewportHeight) {
        divStyle.setProperty('bottom', `${innerHeight - viewportHeight}px`);
        return;
      }

      divStyle.removeProperty('bottom');
    }
  };
}

export interface OverlayProps {
  children: ReactNode;
  slideState: TransitionStatus;
  handleCloseButtonClicked: (event: unknown) => void;
  closeButtonLabel?: string;
  closeButtonProps?: Record<string, unknown>;
  withCloseButton?: boolean;
  drawerContainerRef: RefObject<HTMLDivElement>;
}

const DrawerCloseButton = withTaktFallbackId<CloseButtonProps>(PositionedCloseButton);

export const Overlay = forwardRef<HTMLDivElement, OverlayProps>(({
  slideState,
  withCloseButton = true,
  closeButtonLabel = '',
  handleCloseButtonClicked,
  closeButtonProps = {},
  children,
  drawerContainerRef,
  ...rest
},
ref) => {
  const containerRef = useRef<HTMLDivElement>(null);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    /**
     * Work-around for Android Firefox bug which causes 56px of whitespace
     * at the bottom of the screen when the ContextualDrawer is opened. On resize, if
     * the visualViewport height is greater than the outerHeight of the window, we
     * assume the whitespace is visible and add 56px to the bottom of the BottomSheet
     * to account for the whitespace blocking any content. Will remove once the
     * browser bug is fixed.
     */
    if (typeof window !== 'undefined' && 'visualViewport' in window && window.visualViewport) {
      const { visualViewport } = window;

      const setBottomToVisualViewport = createSetBottomToVisualViewport(containerRef);
      visualViewport.addEventListener('resize', setBottomToVisualViewport);
      setBottomToVisualViewport();

      return () => {
        visualViewport.removeEventListener('resize', setBottomToVisualViewport);
      };
    }
  }, [containerRef]);

  return (
    <DrawerSurface
      {...rest}
      ref={composeRefs(containerRef, ref, drawerContainerRef)}
      slideState={slideState}
      withCloseButton={withCloseButton}
    >
      {withCloseButton && (
        <DrawerCloseButton
          size="lg"
          label={closeButtonLabel}
          onClick={handleCloseButtonClicked}
          taktFallbackId={createStormTaktId('drawer-close-button')}
          {...closeButtonProps}
        />
      )}
      {children}
    </DrawerSurface>
  );
});

Overlay.displayName = 'Overlay';
Overlay.defaultProps = {
  closeButtonLabel: '',
  closeButtonProps: undefined,
  withCloseButton: true,
};
