import React, {
  useState,
  KeyboardEvent,
  useRef,
  useMemo,
} from 'react';
import styled from 'styled-components';
import { times } from '@amzn/storm-ui-icons-v3';
import { keyboardKeynames } from '@amzn/storm-ui-utils-v3';
import { useIntl } from '@amzn/storm-ui-intl-v3';
import Icon from '../Icon';
import Modal from '../Modal/Modal';
import Text from '../Text';
import { useTaktId } from '../TaktIdContext';

const Pill = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.small};
  border: 1px solid ${({ theme }) => theme.palette.gray[150]};
  border-radius: ${({ theme }) => theme.spacing.micro};
  background: none;
  padding: 5px 5px 5px 8px;
  font-size: ${({ theme }) => theme.button.normal.fontSize};
  height: 40px;

  :hover {
    border-color: ${({ theme }) => theme.palette.gray[25]};
  }
`;

const TextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

const FileText = styled(Text)`
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LargeImage = styled('img')`
  max-width: 100%;
  height: auto;
`;

const Thumbnail = styled('div')`
  display: flex;
`;

const SmallImage = styled('img')`
  white-space: nowrap;
  border-radius: 5px;
  height: 40px;
  width: auto;
  max-width: 100px;
  cursor: pointer;
`;

const CloseButton = styled('button')`
  border: none;
  background: ${({ theme }) => theme.palette.gray[800]};
  border-radius: 50%;
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  align-self: flex-start;
  justify-content: center;
  cursor: pointer;
`;

const CloseIcon = styled(Icon)`
  display: flex;
  color: ${({ theme }) => theme.palette.white};
`;

interface AttachmentTileProps {
  attachment: File;
  onClose?: () => void;
}

const AttachmentPill = ({ attachment, onClose }: AttachmentTileProps) => {
  const { formatMessage } = useIntl();

  const thumbnailRef = useRef<HTMLDivElement>(null);

  const [isModalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!isModalOpen)
  }

  const handleKeyDownImage = (event: KeyboardEvent) => {
    if (event.key === keyboardKeynames.ENTER) {
      toggleModal();
    }
  };

  const handleKeyDownIcon = (event: KeyboardEvent) => {
    if (event.key === keyboardKeynames.ENTER) {
      onClose?.();
    }
  };

  const isImage = attachment.type.split('/').includes('image');

  const getImgUrl = useMemo(() => URL.createObjectURL(attachment), [attachment]);

  const splitFileName = attachment.name.split('.');
  const fileName = splitFileName.slice(0, splitFileName.length - 1).join('.');
  const fileExtension = splitFileName.pop();

  const { getDataTaktAttributes } = useTaktId({ fallbackId: 'close-button' });

  return (
    <Pill>
      {isImage ? (
        <>
          <Modal
            isOpen={isModalOpen}
            onClose={toggleModal}
            toggleEl={thumbnailRef.current as unknown as HTMLElement}
            padding="micro"
          >
            <LargeImage alt={attachment.name} src={URL.createObjectURL(attachment)} />
          </Modal>
          <Thumbnail
            aria-haspopup="true"
            ref={thumbnailRef}
            tabIndex={0}
            role="button"
            onKeyDown={handleKeyDownImage}
            onClick={toggleModal}
          >
            <SmallImage
              alt={
                formatMessage({ id: 'thumbnail-alt' }, {
                  attachment: attachment.name,
                })
              }
              src={getImgUrl}
            />
          </Thumbnail>
        </>
      ) : (
        <TextWrapper>
          <FileText type="p">
            {fileName}
          </FileText>
          <FileText type="h6" fontSize="tiny" textColor="tertiary">
            {fileExtension}
          </FileText>
        </TextWrapper>
      )}
      <CloseButton
        {...getDataTaktAttributes()}
        tabIndex={0}
        onClick={onClose}
        onKeyDown={handleKeyDownIcon}
        aria-label={
          formatMessage({ id: 'remove-attachment' }, {
            attachment: attachment.name,
          })
        }
      >
        <CloseIcon
          id={attachment.name}
          type={times}
          size="xs"
        />
      </CloseButton>
    </Pill>
  );
};

export default AttachmentPill;
