import React, {
  useEffect,
  useRef,
  ChangeEvent,
  ReactNode,
  ReactElement,
} from 'react';
import styled, { css } from 'styled-components';
import { scrollIntoBounds } from '@amzn/storm-ui-utils';
import SecondaryView, { SecondaryViewProps } from '../SecondaryView/SecondaryView';
import isMobileStyleMixin from '../theme/style-mixins/isMobile/isMobile';
import { RadioGroupProvider } from '../RadioGroup/RadioGroupContext';
import { createStormTaktId, withTaktFallbackId } from '../TaktIdContext';
import { SelectValue } from '../SelectList/types';
import DropdownDivider from './DropdownDivider';

const DropdownSecondaryView = withTaktFallbackId(SecondaryView)

const ContentHeader = styled.div`
  background: ${({ theme }) => theme.palette.white};
  padding: ${({ theme }) => theme.spacing.mini};
  text-align: start;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.palette.mercury};

  ${isMobileStyleMixin(css`
    padding: ${({ theme }) => theme.mobile.spacing.mini};
  `)}
`;
const ContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  & fieldset {
    background-color: ${({ theme }) => theme.palette.white};
  }
`;

const ScrollContainer = styled.div`
  padding: 10px;
  flex: 1;
  overflow: auto;

  & > label {
    background-color: ${({ theme }) => theme.form.radio.mobile.backgroundColor};
    border: none;
    border-radius: 0;
    border: ${({ theme }) => theme.form.radio.mobile.buttonBorderWidth} solid ${({ theme }) => theme.form.radio.mobile.buttonBorderColor};
    border-top: 0;
  }

  & > label:first-of-type {
    border-top-left-radius: ${({ theme }) => theme.form.radio.mobile.buttonBorderRadius};
    border-top-right-radius: ${({ theme }) => theme.form.radio.mobile.buttonBorderRadius};
    border-top: ${({ theme }) => theme.form.radio.mobile.buttonBorderWidth} solid ${({ theme }) => theme.form.radio.mobile.buttonBorderColor};
  }

  & > label:last-of-type {
    border-bottom-left-radius: ${({ theme }) => theme.form.radio.mobile.buttonBorderRadius};
    border-bottom-right-radius: ${({ theme }) => theme.form.radio.mobile.buttonBorderRadius};
  }

  & > ${DropdownDivider} {
    border: none;
  }

  ${isMobileStyleMixin(css`
    & > fieldset:not(:first-of-type) {
      margin-block-start: ${({ theme }) => theme.mobile.spacing.mini};
    }
  `)}
`;

const ScrollWrapper = (props: { children: ReactNode }) => {
  const radioGroupWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (radioGroupWrapperRef.current) {
      scrollIntoBounds(radioGroupWrapperRef.current, radioGroupWrapperRef.current?.querySelector('input[type="radio"]:checked'));
    }
  }, []);

  return (
    <ScrollContainer
      role="listbox"
      ref={radioGroupWrapperRef}
      {...props}
    />
  );
}

export interface MobileContainerProps extends Pick<SecondaryViewProps, 'isOpen' | 'onClose'> {
  secondaryViewCloseButtonLabel: SecondaryViewProps['closeButtonLabel'];
  anchorEl: SecondaryViewProps['toggleEl'];
  preRender?: () => ReactNode;
  postRender?: () => ReactNode;
  trigger: () => ReactElement;
  children: ReactNode;
  name: string;
  onChange: (
    value?: SelectValue,
    event?: ChangeEvent,
  ) => void;
  selectedValue?: string | undefined;
}

const MobileContainer = ({
  isOpen,
  onClose,
  secondaryViewCloseButtonLabel,
  children,
  trigger,
  anchorEl,
  preRender,
  postRender,
  name,
  onChange,
  selectedValue,
}: MobileContainerProps) => (
  <>
    <DropdownSecondaryView
      isOpen={isOpen}
      onClose={onClose}
      toggleEl={anchorEl}
      padding="none"
      closeButtonLabel={secondaryViewCloseButtonLabel}
      taktFallbackId="dropdown-mobile"
    >
      <ContentWrapper>
        {preRender && (
        <ContentHeader>{preRender()}</ContentHeader>
        )}
        <ScrollWrapper>
          <RadioGroupProvider
            name={name}
            selectedValue={selectedValue === undefined ? undefined : String(selectedValue)}
            handleRadioChange={event => {
              onChange(event.target.value, event);
            }}
          >
            {children}
          </RadioGroupProvider>
          {postRender?.()}
        </ScrollWrapper>
      </ContentWrapper>
    </DropdownSecondaryView>
    {trigger()}
  </>
)
MobileContainer.defaultProps = {
  selectedValue: undefined,
  preRender: undefined,
  postRender: undefined,
}

export default MobileContainer;
