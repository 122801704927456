import React, { PureComponent } from 'react';
import PT from 'prop-types';
import { SelectOption } from '../../SelectList';
import { DropdownItemDisplayName } from '../displayNames';
import { withTaktFallbackId, createStormTaktId } from '../../TaktIdContext';
import { SelectOptionProps } from '../../SelectList/SelectOption/SelectOption';
import { RadioButton } from '../../RadioGroup';
import { DropdownIsMobileConsumer } from '../DropdownIsMobileContext';

const DropdownItemDesktop = withTaktFallbackId(SelectOption)
const DropdownItemMobile = withTaktFallbackId(RadioButton)

export interface DropdownItemProps extends SelectOptionProps {}

export default class DropdownItem extends PureComponent<DropdownItemProps> {
  static displayName = DropdownItemDisplayName

  static propTypes = {
    /**
     * Makes the item un-selectable.
     */
    disabled: PT.bool,
    /**
     * Hides the item from the dropdown.
     */
    hidden: PT.bool,
    /**
     * Function returns additional react nodes that can be used to decorate an
     * item inline before the children are rendered.
     */
    renderStart: PT.func,
    /**
     * Function returns additional react nodes that can be used to decorate an
     * item inline after the children are rendered.
     */
    renderEnd: PT.func,
    /**
     * The value returned when the item is selected.
     */
    value: PT.any, // eslint-disable-line react/forbid-prop-types
    /**
     * Function returns additional react nodes that can be used to render an
     * item outside of its clickable area. ex. Tooltips
     */
    renderAfter: PT.func,
  }

  static defaultProps = {
    disabled: false,
    hidden: false,
    renderStart: undefined,
    renderEnd: undefined,
    value: undefined,
    renderAfter: undefined,
  }

  render(): JSX.Element {
    const {
      disabled,
      value,
      renderStart,
      renderEnd,
      renderAfter,
      children,
      selected,
      onClick,
      ...rest
    } = this.props;
    const taktFallbackId = createStormTaktId('dropdown-item')

    return (
      <DropdownIsMobileConsumer>
        {isMobile => {
          if (isMobile) {
            return (
              <>
                <DropdownItemMobile
                  {...rest}
                  taktFallbackId={taktFallbackId}
                  id={value}
                  value={value}
                  label={(
                    <>
                      {renderStart && renderStart()}
                      {children}
                      {renderEnd && renderEnd()}
                    </>
                  )}
                  disabled={disabled}
                  isChecked={selected}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (onClick) {
                      onClick(event.target.value, event);
                    }
                  }}
                />
                {renderAfter?.()}
              </>
            );
          }

          return (
            <DropdownItemDesktop
              {...rest}
              taktFallbackId={taktFallbackId}
              value={value}
              renderStart={renderStart}
              renderEnd={renderEnd}
              renderAfter={renderAfter}
              disabled={disabled}
              selected={selected}
              onClick={onClick}
            >
              {children}
            </DropdownItemDesktop>
          );
        }}
      </DropdownIsMobileConsumer>
    );
  }
}
