import React, {
  ReactElement, ReactNode, FC, MouseEventHandler,
} from 'react';
import PT from 'prop-types';
import {
  MergeElementProps,
  noop,
} from '@amzn/storm-ui-utils';
import { BasePlacement, Variation } from '@popperjs/core';
import PopperContainer from '../Popper/PopperContainer';
import TooltipContent from './TooltipContent';
import InteractiveContainer from './InteractiveContainer';
import NonInteractiveContainer from './NonInteractiveContainer';

const TRIGGER_MESSAGE_ID = 'message_for_trigger';

export interface TooltipProps extends MergeElementProps<'div'> {
  /**
   * @defaultValue `"center"`
   */
  align?: Variation | 'center';
  /**
   * Label for close button.
   * @defaultValue `"Close Tooltip"`
   */
  closeButtonLabel?: string;
  /**
   * Disables the react portal and renders the component inline.
   * This is necessary if you are rendering the tooltip in a scroll pane that is not the window.
   * @defaultValue `false`
   */
  disablePortal?: boolean;
  /**
   * Keeps tooltip open regardless of mouseover. Used mostly for debug.
   * @defaultValue `false`
   */
  forceOpen?: boolean;
  /**
   * The default way of using tooltip. If you need custom content, use children.
   * @defaultValue `""`
   */
  message?: string | ReactNode;
  /**
   * Bool, that when true indicates there’s no interactive content in the tooltip,
   * and that it should behave like an official aria tooltip
   * (activates on hover or focus, content read as part of the label for the trigger element by screen readers)
   * instead of the default “toggletip” behavior
   * (activates on hover or focus + click, focus is moved to and trapped in the tooltip body)
   * @defaultValue `false`
   */
  nonInteractive?: boolean;
  /**
   * The function called on clicking the Tooltip.
   * @defaultValue `undefined`
   */
  onClick?: MouseEventHandler<HTMLSpanElement>;
  /**
   * Used to close the tooltip on callback.
   * @defaultValue `() => undefined`
   */
  onCloseButtonClick?: MouseEventHandler<HTMLButtonElement>;
  /**
   * The position relative to the trigger.
   * @defaultValue `"top"`
   */
  position?: BasePlacement;
  /**
   * Fine grain control over the space between the PIP and the anchor.
   * @defaultValue `0`
   */
  spacing?: number;
  /**
   * This element will render the tooltip when moused over.
   */
  trigger: ReactElement;
  /**
   * One of:`light`, `dark`,`blue`.
   * @defaultValue `"light"`
   */
  type?: 'light' | 'dark' | 'blue';
  /**
   * Set to false to remove close button. Note close button
   * is not shown if forceOpen is set to true.
   * @defaultValue `true`
   */
  withCloseButton?: boolean;
  /**
   * The footer node.
   * @defaultValue `undefined`
   */
  footer?: ReactNode;
  /**
   * @defaultValue `"default"`
   */
  closeIconPosition?: 'default' | 'topCorner';
  /**
   * Disables tooltip opening on hover or on keydown.
   * @defaultValue `false`
   */
  inert?: boolean;
}

const Tooltip: FC<React.PropsWithChildren<TooltipProps>> = props => {
  const {
    align = 'center',
    children,
    closeButtonLabel = 'Close Tooltip',
    closeIconPosition = 'default',
    disablePortal,
    footer,
    forceOpen = false,
    inert = false,
    message,
    nonInteractive,
    onClick,
    onCloseButtonClick,
    position = 'top',
    spacing = 0,
    trigger,
    type = 'light',
    withCloseButton: externalWithCloseButton = true,
    ...rest
  } = props;
  const Container = nonInteractive ? NonInteractiveContainer : InteractiveContainer;
  const messageId = nonInteractive ? TRIGGER_MESSAGE_ID : undefined;

  return (
    <Container
      align={align}
      forceOpen={forceOpen}
      inert={inert}
      onClick={onClick}
      onCloseButtonClick={onCloseButtonClick}
      position={position}
      spacing={spacing}
      trigger={trigger}
      triggerAriaDescribedby={messageId}
      withCloseButton={externalWithCloseButton}
    >
      {({
        attributes,
        focusableBody,
        handleCloseClicked,
        handleKeyDown,
        handleKeyUp,
        handleMouseEnter,
        handleMouseLeave,
        handlePopoverEntered,
        handlePopoverEntering,
        handlePopoverExited,
        isOpen,
        setArrowElement,
        setPopperElement,
        styles,
        withCloseButton,
      }) => (
        <PopperContainer
          {...rest}
          attributes={attributes}
          closeButtonLabel={closeButtonLabel}
          closeIconPosition={closeIconPosition}
          disablePortal={disablePortal}
          focusableBody={focusableBody}
          footer={footer}
          handleCloseClicked={handleCloseClicked}
          handleKeyDown={handleKeyDown}
          handleKeyUp={handleKeyUp}
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
          handlePopoverEntered={handlePopoverEntered}
          handlePopoverEntering={handlePopoverEntering}
          handlePopoverExited={handlePopoverExited}
          isOpen={isOpen}
          message={message}
          position={position}
          setArrowElement={setArrowElement}
          setPopperElement={setPopperElement}
          styles={styles}
          type={type}
          withArrow
          withCloseButton={withCloseButton}
          popoverType="tooltip"
        >
          <TooltipContent
            closeButtonLabel={closeButtonLabel}
            closeIconPosition={closeIconPosition}
            footer={footer}
            handleCloseClicked={handleCloseClicked}
            message={message}
            messageId={messageId}
            type={type}
            withCloseButton={withCloseButton}
          >
            {children}
          </TooltipContent>
        </PopperContainer>
      )}
    </Container>
  );
};

Tooltip.propTypes = {
  /**
   * This element will render the tooltip when moused over.
   */
  trigger: PT.element.isRequired,
  align: PT.oneOf(['start', 'center', 'end']),
  /**
   * Use message prop instead, unless you need custom content.
   */
  children: PT.node,
  /**
   * Disables tooltip opening on hover or on keydown.
   */
  inert: PT.bool,
  /**
   * Disables the react portal and renders the component inline.
   * This is necessary if you are rendering the tooltip in a scroll pane that is not the window.
   */
  disablePortal: PT.bool,
  /**
   * Keeps tooltip open regardless of mouseover. Used mostly for debug.
   */
  forceOpen: PT.bool,
  /**
   * The `id` attribute that is supplied to the popover element.
   * Useful for identifying the tooltip in front-end telemetry.
   */
  id: PT.string,
  /**
   * The default way of using tooltip. If you need custom content, use children.
   */
  message: PT.oneOfType([
    PT.string,
    PT.node,
  ]),
  /**
   * Bool, that when true indicates there’s no interactive content in the tooltip,
   * and that it should behave like an official aria tooltip
   * (activates on hover or focus, content read as part of the label for the trigger element by screen readers)
   * instead of the default “toggletip” behavior
   * (activates on hover or focus + click, focus is moved to and trapped in the tooltip body)
   */
  nonInteractive: PT.bool,
  /**
   * The function called on clicking the Tooltip.
   */
  onClick: PT.func,
  /**
   * Used to close the tooltip on callback.
   */
  onCloseButtonClick: PT.func,
  /**
   * The position relative to the trigger.
   */
  position: PT.oneOf(['top', 'right', 'bottom', 'left']),
  /**
   * Fine grain control over the space between the PIP and the anchor.
   */
  spacing: PT.number,
  /**
   * One of:`light`, `dark`,`blue`.
   */
  type: PT.oneOf(['light', 'dark', 'blue']),
  /**
   * Set to false to remove close button. Note close button
   * is not shown if forceOpen is set to true.
   */
  withCloseButton: PT.bool,
  /**
   * Label for close button.
   */
  closeButtonLabel: PT.string,
  /**
   * The footer node.
   */
  footer: PT.node,
  closeIconPosition: PT.oneOf(['default', 'topCorner']),
};

Tooltip.defaultProps = {
  align: 'center',
  children: undefined,
  closeButtonLabel: 'Close Tooltip',
  closeIconPosition: 'default',
  inert: false,
  disablePortal: false,
  footer: undefined,
  forceOpen: false,
  id: undefined,
  message: '',
  nonInteractive: false,
  onClick: undefined,
  onCloseButtonClick: noop,
  position: 'top',
  spacing: 0,
  type: 'light',
  withCloseButton: true,
};

export default Tooltip;
