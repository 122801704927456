import React, { ComponentPropsWithoutRef, PureComponent } from 'react';
import PT from 'prop-types';
import styled, { css } from 'styled-components';
import isMobile from '../theme/style-mixins/isMobile/isMobile';
import { TaktIdProvider, createStormTaktId } from '../TaktIdContext';
import { TaktProps } from '../types/TaktProps';

const StyledList = styled.ul`
  ${({ theme }) => theme.typography.base}
  color: ${({ theme }) => theme.typography.color.base};
  margin: 0;
  margin-inline-start: 1.5em;
  display: block;
  ${isMobile(css`${({ theme }) => theme.typography.mobile.base}`)}
`;

export interface UnorderedListProps extends TaktProps, ComponentPropsWithoutRef<'ul'> {}
export default class UnorderedList extends PureComponent<UnorderedListProps> {
  static propTypes = {
    children: PT.node.isRequired,
  }

  render(): JSX.Element {
    const {
      children,
      taktId,
      taktValue,
      ...rest
    } = this.props;
    return (
      <TaktIdProvider taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('unordered-list')}>
        <StyledList {...rest}>
          {children}
        </StyledList>
      </TaktIdProvider>
    );
  }
}
