import React, { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { MergeElementProps } from '@amzn/storm-ui-utils-v3';
import isMobile from '../../theme/style-mixins/isMobile/isMobile';
import Divider from '../../Divider/Divider';
import Text from '../../Text/Text';
import { StyledList } from '../MenuList/MenuList';
import { TaktProps } from '../../types/TaktProps';
import { TaktIdProvider, createStormTaktId } from '../../TaktIdContext';

export interface MenuItemGroupProps extends TaktProps, MergeElementProps<'li'> {
  /**
   * Labels a group of related menu items. Accepts a ReactNode to allow for custom labels
   * and additional elements to be rendered.
   * @defaultValue `undefined`
   */
  label?: ReactNode;
}

export const MenuDivider = styled(Divider)`
  margin: ${({ theme }) => theme.spacing.mini} 0;
`;
MenuDivider.displayName = 'MenuDivider';

export const MenuItemGroupListItem = styled('li')`
  text-align: start;

  :first-child ${MenuDivider} {
    display: none;
  };
`;
MenuItemGroupListItem.displayName = 'MenuItemGroupListItem';

export const MenuItemGroupLabel = styled(Text).attrs({
  type: 'span',
  textColor: 'tertiary',
})`
  color: ${({ theme }) => theme.dropdown.group.headingColor};
  user-select: none;
  font-weight: bold;
  font-size: ${({ theme }) => theme.typography.size.base};
  padding: 1px 11px 0px 11px;
  text-transform: none;

  ${isMobile(css`
    ${({ theme }) => theme.typography.mobile.base};
  `)}
`;
MenuItemGroupLabel.displayName = 'MenuItemGroupLabel';

const MenuItemGroup: FC<React.PropsWithChildren<MenuItemGroupProps>> = ({
  children,
  label,
  onClick,
  taktId,
  taktValue,
  ...rest
}) => (
  <TaktIdProvider taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('menu-item-group')}>
    <MenuItemGroupListItem
      {...rest}
      role="group"
    >
      <MenuDivider />
      {label && (<MenuItemGroupLabel>{label}</MenuItemGroupLabel>)}
      <StyledList>
        {children}
      </StyledList>
    </MenuItemGroupListItem>
  </TaktIdProvider>
);

MenuItemGroup.propTypes = {
  label: PropTypes.node,
};
MenuItemGroup.defaultProps = {
  label: undefined,
};

export default MenuItemGroup;
