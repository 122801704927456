import React, {
  FC, ReactNode,
  isValidElement,
  Children,
  cloneElement,
} from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { MergeStyledComponentElementProps } from '@amzn/storm-ui-utils-v3';
import isMobile from '../../theme/style-mixins/isMobile/isMobile';

const StyledLegend = styled('legend') <{
  hidden?: boolean;
  disabled?: boolean;
}>`
  ${({ theme }) => theme.typography.base};
  color: ${({ theme }) => theme.form.label.color};
  font-weight: ${({ theme }) => theme.form.label.weight};
  padding: 0;

  ${({ hidden, theme }) => (hidden && theme.typography.screenReaderOnly)}

  ${isMobile(css`
    ${({ theme }) => theme.typography.mobile.base};
    margin-bottom: ${({ theme }) => theme.spacing.micro};
  `)}

  /* AUI Overrides */
  display: block;
  box-sizing: border-box;

  /* Unset user agent styles */
  padding-inline-start: unset;
  padding-inline-end: unset;

  ${({ disabled }) => disabled && css`
    cursor: not-allowed;
    outline: none;
    color: ${({ theme }) => theme.form.label.colorDisabled};
  `};
`;
StyledLegend.displayName = 'StyledLegend';

const CheckboxGroupItem = styled('div')`
  margin-bottom: 4px;
  word-wrap: break-word;

  ${isMobile(css`
    background-color: ${({ theme }) => theme.form.checkbox.mobile.groupBackgroundColor};
    border-bottom: 1px solid ${({ theme }) => theme.form.checkbox.mobile.groupBorderColor};
    margin: 0;
    padding: 0 ${({ theme }) => theme.mobile.spacing.small};

    :first-of-type {
      border-radius: ${({ theme }) => theme.form.checkbox.mobile.groupBorderRadius} ${({ theme }) => theme.form.checkbox.mobile.groupBorderRadius} 0 0;
    }

    :last-of-type {
      border-radius: 0 0 ${({ theme }) => theme.form.checkbox.mobile.groupBorderRadius} ${({ theme }) => theme.form.checkbox.mobile.groupBorderRadius};
      border-bottom: none;
    }
  `)}

  label {
    width: 100%;
  }
`;

interface CheckboxGroupContainerProps {
  $groupIndicator: 'bar' | 'none';
}

const CheckboxGroupContainer = styled('div')<CheckboxGroupContainerProps>`
  ${({ $groupIndicator }) => ($groupIndicator === 'bar' ? css`
    border-inline-start: 1px solid ${({ theme }) => theme.palette.gray[300]};
    padding-inline-start: 14px;
    margin-inline-start: 7px;
  ` : undefined)}
  ${isMobile(css`
    background-color: ${({ theme }) => theme.form.checkbox.mobile.groupBackgroundColor};
    border: 1px solid ${({ theme }) => theme.form.checkbox.mobile.groupBorderColor};
    border-radius: ${({ theme }) => theme.form.checkbox.mobile.groupBorderRadius};
    display: block;
    padding-inline-start: unset;
    margin-inline-start: unset;
  `)}
`;

const CheckboxGroupFieldset = styled('fieldset')`
  border: none;
  margin: 0;
  padding: 0;

  /* Unset user agent styles */
  margin-block-start: unset;
  margin-block-end: unset;
  margin-inline-start: unset;
  margin-inline-end: unset;
  padding-inline-start: unset;
`;

export interface CheckboxGroupProps extends MergeStyledComponentElementProps<'fieldset'> {
  /**
   * Specifies if the checkbox group is disabled, which disables the entire group if true. If this
   * is false, checkboxes can still be disabled individually.
   * @defaultValue `false`
   */
  disabled?: boolean;
  /**
   * Labels are required for screen reader accessibility, but can be hidden visually.
   * @defaultValue `false`
   */
  hideLabel?: boolean;
  /**
   * The group's label text. Labels are required for screen reader accessibility.
   */
  label?: ReactNode;
  /**
   * An array of <Checkbox /> components.
   */
  children: ReactNode[];
  /**
   * Utilized when wrapping the checkbox in a styled-component. Passes the styles specified by the
   * styled-component to the checkbox wrapper component.
   * @defaultValue `undefined`
   */
  className?: string;
  /**
   * Adds an inline border to indicate grouped items
   * @defaultValue `"none"`
   */
  groupIndicator?: 'bar' | 'none';
}

const CheckboxGroup: FC<React.PropsWithChildren<CheckboxGroupProps>> = ({
  children,
  className,
  disabled,
  hideLabel,
  label,
  groupIndicator = 'none',
  ...rest
}) => (
  children && (
    <CheckboxGroupFieldset className={className} disabled={disabled} {...rest}>
      <StyledLegend disabled={disabled} hidden={hideLabel}>
        {label}
      </StyledLegend>
      <CheckboxGroupContainer $groupIndicator={groupIndicator}>
        {Children.map(children, child => {
          /* Only set disabled when the child is react Element */
          if (isValidElement(child)) {
            return (
              <CheckboxGroupItem>
                {cloneElement(child, {
                  ...child.props,
                  disabled: disabled || child.props.disabled,
                })}
              </CheckboxGroupItem>
            );
          }
          return child;
        })}
      </CheckboxGroupContainer>
    </CheckboxGroupFieldset>
  )
);

CheckboxGroup.propTypes = {
  /**
   * An array of <Checkbox /> components.
   */
  children: PropTypes.arrayOf<ReactNode>(PropTypes.node).isRequired,
  /**
   * Utilized when wrapping the checkbox in a styled-component. Passes the styles specified by the
   * styled-component to the checkbox wrapper component.
   */
  className: PropTypes.string,
  /**
   * The group's label text. Labels are required for screen reader accessibility.
   */
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  /**
   * Specifies if the checkbox group is disabled, which disables the entire group if true. If this
   * is false, checkboxes can still be disabled individually.
   */
  disabled: PropTypes.bool,
  /**
   * Labels are required for screen reader accessibility, but can be hidden visually.
   */
  hideLabel: PropTypes.bool,
  /**
   * Adds an inline border to indicate grouped items
   */
  groupIndicator: PropTypes.oneOf(['none', 'bar']),
};

CheckboxGroup.defaultProps = {
  disabled: false,
  hideLabel: false,
  className: undefined,
  groupIndicator: 'none',
};

CheckboxGroup.displayName = 'CheckboxGroup';

export default CheckboxGroup;
