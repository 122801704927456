/**
 * getElement "unboxes" references to get the value they are holding *OR* returns the value if a
 * non-references is given.
 * @param maybeRef A DOM Element or a react Ref to a DOM Element
 * @returns DOM Element
 */
export default function getElement<T>(maybeRef?: T | {current:T}): T | undefined {
  if (typeof maybeRef === 'undefined' || maybeRef === null) {
    return maybeRef;
  }
  if (maybeRef !== null && typeof maybeRef !== 'undefined' && 'current' in maybeRef) {
    return maybeRef.current;
  }
  return maybeRef;
}
